import { Select } from "antd";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { useQuery } from "react-query";
import { QueryKeys } from "../../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import { getDictionaryLanguages } from "../../../../data-access/getDictionaryLanguages";
import { DictionaryLanguageT } from "../../../../types";
import DoubleFilter from "../../components/DoubleFilter";
import SelectWithAdvancedOptions from "../../components/SelectWithAdvancedOptions";

const defaultWeight = "0.05";

const Language: FC = () => {
  const [languages, setLanguages] = useState<DictionaryLanguageT[]>([]);

  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );

  const value = searchGlobalQuery.lang.code;

  const { t } = useTranslate("brands.influencer_database.filter.language");

  const { isLoading } = useQuery(
    QueryKeys.DICTIONARY_LANGUAGES,
    () => getDictionaryLanguages(),
    {
      onSuccess: (data) => {
        setLanguages(data);
      },
    },
  );

  const onSelectHandle = (code: number | string | null): void => {
    if (code === null || languages === undefined) return;

    setPartialSearchGlobalQuery({
      audience_lang: { code: code.toString(), weight: defaultWeight },
    });
  };

  const onDeselectHandle = (id: number | string | null): void => {
    if (id === null) return;

    setPartialSearchGlobalQuery({ audience_lang: { code: "", weight: "" } });
  };

  return (
    <DoubleFilter
      closable={["audience_lang", "lang"]}
      audienceFilter={
        <SelectWithAdvancedOptions
          searchQuery={searchGlobalQuery}
          setFilterSearchQuery={setPartialSearchGlobalQuery}
          attribute="audience_lang"
          onSelectHandle={onSelectHandle}
          onDeselectHandle={onDeselectHandle}
          options={languages.map((lang) => ({
            id: lang.code,
            name: lang.name,
          }))}
          t={t}
          isLoading={isLoading}
        />
      }
      influencerFilter={
        <Select
          allowClear
          showSearch
          value={value.length > 0 ? value : undefined}
          options={languages.map((lang) => ({
            label: lang.name,
            value: lang.code,
          }))}
          onChange={(value) =>
            setPartialSearchGlobalQuery({ lang: { code: value ? value : "" } })
          }
          style={{ width: "100%" }}
          placeholder={t("placeholder")}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      }
      t={t}
    />
  );
};

export default Language;
