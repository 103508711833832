import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext } from "react";
import Paragraph from "../../../../components/Paragraph";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import { designToken } from "../../../../helpers/antDesign";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import {
  SubscriptionEntitlementFeaturesT,
  SubscriptionEntitlementT,
  SubscriptionPlansT,
} from "../../../types";
import Entitlement from "./Entitlement";

const SectionParagraph = styled(Paragraph)({
  margin: `${rem(4)} 0 ${rem(12)} 0`,
  color: designToken.colorTextTertiary,
});

type SubscriptionPlanEntitlementsT = {
  planId: SubscriptionPlansT | "custom";
  entitlements: SubscriptionEntitlementT[];
};

const SubscriptionPlanEntitlements: FC<SubscriptionPlanEntitlementsT> = ({
  planId,
  entitlements,
}) => {
  const { session } = useContext(ApplicationContext);
  const { t } = useTranslate("brands.subscription_plans.entitlements");

  const isAllowedCountry = ["CZ", "SK"].includes(
    session?.brand?.billing_address?.country_code || "",
  );

  const showEntitlement = (
    featureId: SubscriptionEntitlementFeaturesT,
    showValue: boolean = true,
    translateValue = false,
  ): JSX.Element | undefined => {
    const entitlement: SubscriptionEntitlementT | undefined =
      featureId === "database-access-cz"
        ? { feature_id: "database-access-cz", value: false }
        : entitlements.find((e) => e.feature_id === featureId);

    if (entitlement) {
      return (
        <Entitlement
          entitlement={entitlement}
          value={
            planId === "enterprise" && entitlement.feature_id !== "support"
              ? t("custom")
              : entitlement.value.toString()
          }
          showValue={showValue}
          translateValue={translateValue}
          check={
            !(
              planId === "lite" &&
              entitlement.feature_id === "database-access-cz"
            )
          }
          tooltipValue={
            entitlement.feature_id === "database-access-cz"
              ? t(`tooltips.${entitlement.feature_id.replaceAll("-", "_")}`)
              : undefined
          }
        />
      );
    }

    return undefined;
  };

  return (
    <>
      <SectionParagraph
        paragraphSize="small"
        paragraph={t("global_database")}
      />
      {showEntitlement("database-searches")}
      {showEntitlement("database-reports")}
      {isAllowedCountry && showEntitlement("database-access-cz", false, false)}
      <SectionParagraph paragraphSize="small" paragraph={t("research_tools")} />
      {showEntitlement("overlap-analyses")}
      {showEntitlement("competitive-analyses")}
      <SectionParagraph paragraphSize="small" paragraph={t("campaigns")} />
      {showEntitlement("reporting-influencers")}
      <SectionParagraph paragraphSize="small" paragraph={t("general")} />
      {showEntitlement("users")}
      {showEntitlement("support", false, true)}
    </>
  );
};

export default SubscriptionPlanEntitlements;
