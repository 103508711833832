import { Select } from "antd";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { useQuery } from "react-query";
import { QueryKeys } from "../../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import { getDictionaryInterests } from "../../../../data-access/getDictionaryInterests";
import { DictionaryInterestT } from "../../../../types";
import DoubleFilter from "../../components/DoubleFilter";
import SelectWithAdvancedOptions from "../../components/SelectWithAdvancedOptions";

const defaultWeight = "0.6";

const Interests: FC = () => {
  const [interests, setInterests] = useState<DictionaryInterestT[]>([]);
  const [open, setOpen] = useState(false);

  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );

  const values = searchGlobalQuery.brand_category
    .map((id) => interests.find((interest) => interest.id === parseInt(id))?.id)
    .filter((id) => id !== undefined) as number[];

  const { t } = useTranslate("brands.influencer_database.filter.interests");

  useQuery(QueryKeys.DICTIONARY_INTERESTS, () => getDictionaryInterests(), {
    onSuccess: (data) => {
      setInterests(data);
    },
  });

  const onAdvancedSelectHandle = (id: number | string | null): void => {
    if (id === null) return;

    const added = interests.find((interest) => interest.id === id);

    added &&
      setPartialSearchGlobalQuery({
        audience_brand_category: [
          ...searchGlobalQuery.audience_brand_category,
          { id: added.id.toString(), weight: defaultWeight },
        ],
      });
  };

  const onAdvancedDeselectHandle = (id: number | string | null): void => {
    if (id === null) return;

    setPartialSearchGlobalQuery({
      audience_brand_category: searchGlobalQuery.audience_brand_category.filter(
        (interest) => interest.id !== id.toString(),
      ),
    });
  };

  const onSelectHandle = (id: number): void => {
    setPartialSearchGlobalQuery({
      brand_category: [...searchGlobalQuery.brand_category, id.toString()],
    });
    setOpen(false);
  };

  const onDeselectHandle = (id: number): void => {
    setPartialSearchGlobalQuery({
      brand_category: searchGlobalQuery.brand_category.filter(
        (interestId) => interestId !== id.toString(),
      ),
    });
    setOpen(false);
  };

  return (
    <DoubleFilter
      closable={["audience_brand_category", "brand_category"]}
      audienceFilter={
        <SelectWithAdvancedOptions
          searchQuery={searchGlobalQuery}
          setFilterSearchQuery={setPartialSearchGlobalQuery}
          attribute="audience_brand_category"
          onSelectHandle={onAdvancedSelectHandle}
          onDeselectHandle={onAdvancedDeselectHandle}
          options={interests.map((interest) => ({
            id: interest.id,
            name: interest.name,
          }))}
          t={t}
        />
      }
      influencerFilter={
        <Select
          showSearch
          mode="multiple"
          value={values}
          open={open}
          onDropdownVisibleChange={(visible) => setOpen(visible)}
          style={{ width: "100%" }}
          onSelect={(val) => onSelectHandle(val)}
          onDeselect={onDeselectHandle}
          options={interests.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          placeholder={t("placeholder")}
        />
      }
      t={t}
    />
  );
};

export default Interests;
