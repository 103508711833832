import styled from "@emotion/styled";
import FlexBox from "../../../../../../components/FlexBox";
import Paragraph from "../../../../../../components/Paragraph";
import { designToken } from "../../../../../../helpers/antDesign";
import { hexToRgb, rem, theme } from "../../../../../../styling/theme";
import TableColumn from "../../../../../../ui/table/TableColumn";

export const Wrapper = styled(FlexBox)<{ clickable: boolean }>(
  ({ clickable }) => ({
    width: "100%",
    flexDirection: "column",
    backgroundColor: theme.color.whiteColor,
    margin: `${rem(6)} 0`,
    borderRadius: rem(8),

    div: {
      cursor: clickable ? "pointer" : "default",
    },

    ":hover": {
      backgroundColor: hexToRgb(theme.color.backgroundColor, 0.4),
    },

    [theme.media.sm]: {
      flexDirection: "row",
      height: rem(88),
      margin: 0,
      borderRadius: 0,
    },
  }),
);

export const Section = styled(FlexBox)<{ width: string }>(({ width }) => ({
  width: "100%",

  [theme.media.sm]: {
    width: width,
  },
}));

export const TableDynamicColumn = styled(TableColumn)({
  WebkitMaskImage: "linear-gradient(90deg, #000 85%, transparent)",
});

export const CredibilityWrapper = styled(FlexBox)<{ color: string }>(
  ({ color }) => ({
    "p:hover": {
      color: color,
    },
  }),
);

export const StyledParagraph = styled(Paragraph)({
  wordBreak: "normal",
  fontSize: rem(14),
  textWrap: "nowrap",
});

export const ThumbnailsColumn = styled(TableColumn)({
  position: "relative",
});

export const BackgroundImages = styled(FlexBox)<{ blurred: boolean }>(
  ({ blurred }) => ({
    position: "absolute",
    gap: rem(8),
    filter: blurred ? "blur(3px)" : "none",

    "img:last-child": {
      display: "none",
    },

    ":after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: blurred ? theme.color.whiteColor : "transparent",
      opacity: 0.75,
    },

    [`@media screen and (min-width: ${1200}px)`]: {
      "img:last-child": {
        display: "block",
      },
    },

    [theme.media.maxSm]: {
      justifyContent: "space-around",
    },
  }),
);

export const OudatedTag = styled(FlexBox)({
  position: "absolute",
  backgroundColor: designToken["orange-1"],
  borderRadius: rem(16),
  padding: `${rem(2)} ${rem(8)}`,
});

export const Thumbnail = styled.img({
  width: rem(64),
  height: rem(64),
  borderRadius: rem(4),
  overflow: "hidden",
  objectFit: "cover",
});
