import { InstagramOutlined, YoutubeOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Radio, Space } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import FlexBox from "../../../../../components/FlexBox";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import { TikTokIcon } from "../../../../../ui/icons/CustomIcons";
import { NetworkProviderT } from "../../../../types";
import { SearchGlobalQueryContext } from "../../../context-providers/SearchGlobalQueryContextProvider";
import { SubscriptionContext } from "../../../context-providers/SubscriptionsContextProvider";
import FilterContainer from "../components/FilterContainer";

const LabelWrapper = styled(FlexBox)({
  cursor: "pointer",
});

const Label = styled.span({
  paddingLeft: rem(8),
});

type NetworksT = {
  afterChangeHandle: () => void;
};

const Networks: FC<NetworksT> = ({ afterChangeHandle }) => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery, defaultQuery } =
    useContext(SearchGlobalQueryContext);
  const { subscription } = useContext(SubscriptionContext);

  const entitlement = subscription?.entitlements?.find(
    (e) => e.feature_id === "database-access-cz",
  );
  const { t } = useTranslate("brands.influencer_database.filter.networks");

  const onClickHandle = (value: NetworkProviderT): void => {
    const followers = { ...defaultQuery.followers };
    if (value === "tiktok" && entitlement) {
      followers.left_number = "10000";
    }

    const query = { ...defaultQuery, platform: value, followers: followers };
    setPartialSearchGlobalQuery(query);

    afterChangeHandle();
  };

  return (
    <FilterContainer t={t} tooltip={false}>
      <Radio.Group
        name="provider"
        value={searchGlobalQuery.platform}
        onChange={(e) => onClickHandle(e.target.value)}
      >
        <Space direction="vertical">
          <Radio value="instagram">
            <LabelWrapper>
              <InstagramOutlined />
              <Label>Instagram</Label>
            </LabelWrapper>
          </Radio>
          <Radio value="tiktok">
            <LabelWrapper>
              <TikTokIcon />
              <Label>TikTok</Label>
            </LabelWrapper>
          </Radio>
          <Radio value="youtube">
            <LabelWrapper>
              <YoutubeOutlined />
              <Label>YouTube</Label>
            </LabelWrapper>
          </Radio>
        </Space>
      </Radio.Group>
    </FilterContainer>
  );
};

export default Networks;
