import { Button, Tooltip } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { AxiosError } from "axios";
import * as React from "react";
import { createContext, FC, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { designToken } from "../../../../../helpers/antDesign";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem } from "../../../../../styling/theme";
import {
  InfluencerReportWithNetworkProfileT,
  NetworkProviderT,
} from "../../../../types";
import { SubscriptionContext } from "../../../context-providers/SubscriptionsContextProvider";
import { ToastContext } from "../../../context-providers/ToastContextProvider";
import { createInfluencerReport } from "../../../data-access/createInfluencerReport";
import { updateCurrentUsageMutation } from "../../../data-access/mutation/updateCurrentUsageMutation";
import { NavigationKeyE, useRouterNavigate } from "../../../router";

type AnalyzeOptionsT = {
  handle: string;
  platform: NetworkProviderT;
  afterSuccess?: (data: InfluencerReportWithNetworkProfileT) => void;
};

type AnalyzeContextT = {
  analyze: (options: AnalyzeOptionsT) => void;
  analyzeButton: (
    handle: string,
    width?: number,
    onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    type?: "analyze" | "update",
    tooltip?: boolean,
  ) => JSX.Element;
  analyzeTooltip: (
    children: JSX.Element,
    placement: TooltipPlacement,
  ) => JSX.Element;
};

export const AnalyzeContext = createContext<AnalyzeContextT>({
  analyze: () => {},
  analyzeButton: () => <></>,
  analyzeTooltip: () => <></>,
});

export const AnalyzeContextProvider: FC = ({ children }) => {
  const [loadingState, setLoadingState] = useState<{ [key: string]: boolean }>(
    {},
  );

  const navigate = useRouterNavigate();
  const { openToast } = useContext(ToastContext);
  const { upgradeTrialButton, onUpgradeButtonHandle } =
    useContext(SubscriptionContext);
  const { getFeatureUsage } = useContext(SubscriptionContext);
  const { t } = useTranslate("brands.influencer_database");

  const usage = getFeatureUsage("database-reports");
  const usedState = usage ? `${usage.used}/${usage.quota}` : "";
  const reachedLimit = usage ? usage.used >= usage.quota : false;

  const defaultAfterSuccess = (
    data: InfluencerReportWithNetworkProfileT,
  ): void => {
    navigate(NavigationKeyE.Database, `${data.network_profile.id}/overview`);
  };

  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (options: AnalyzeOptionsT) =>
      createInfluencerReport(options.handle, options.platform),
    {
      onMutate: (variables) => {
        setLoadingState((prevState) => ({
          ...prevState,
          [variables.handle]: true,
        }));
      },
      onSuccess: (data, variables) => {
        updateCurrentUsageMutation(queryClient, "database-reports");
        variables.afterSuccess
          ? variables.afterSuccess(data)
          : defaultAfterSuccess(data);
        openToast({
          type: "success",
          message: t("table.analyze.success", { handle: variables.handle }),
        });
      },
      onError: (error: AxiosError) => {
        error.response?.status === 422 &&
          openToast({
            type: "error",
            message: t("table.analyze.quota_exceeded"),
          });
      },
      onSettled: (_data, _error, variables) => {
        setTimeout(() => {
          setLoadingState((prevState) => ({
            ...prevState,
            [variables.handle]: false,
          }));
        }, 1500);
      },
    },
  );

  const analyze = (options: AnalyzeOptionsT): void => {
    if (reachedLimit) {
      onUpgradeButtonHandle();
    } else {
      mutate(options);
    }
  };

  const analyzeTooltip = (
    children: JSX.Element,
    placement: TooltipPlacement = "top",
    enabled: boolean = true,
  ): JSX.Element => (
    <Tooltip
      placement={placement}
      title={
        enabled ? (
          <FlexBox flexDirection="column">
            <Paragraph
              paragraph={t("table.analyze.tooltip.title")}
              color={designToken.colorWhite}
              fontWeight={600}
              paragraphSize="small"
            />
            <Paragraph
              paragraph={t("table.analyze.tooltip.state", {
                state: usedState,
              })}
              color={designToken.colorWhite}
              paragraphSize="micro"
            />
          </FlexBox>
        ) : null
      }
    >
      {children}
    </Tooltip>
  );

  const analyzeButton = (
    handle: string,
    width?: number,
    onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    type: "analyze" | "update" = "analyze",
    tooltip: boolean = true,
  ): JSX.Element => {
    return analyzeTooltip(
      <>
        {reachedLimit ? (
          upgradeTrialButton()
        ) : (
          <Button
            loading={loadingState[handle] || false}
            style={{ width: width ? rem(width) : "auto" }}
            type={type === "analyze" ? "primary" : "link"}
            onClick={onClick ? (e) => onClick(e) : () => {}}
          >
            {t(`search.${type === "analyze" ? "analyze" : "update"}`)}
          </Button>
        )}
      </>,
      "top",
      tooltip,
    );
  };

  return (
    <AnalyzeContext.Provider
      value={{
        analyze,
        analyzeButton,
        analyzeTooltip,
      }}
    >
      {children}
    </AnalyzeContext.Provider>
  );
};
