import styled from "@emotion/styled";
import { Button } from "antd";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { putSpacesToThousand } from "../../../../helpers/formaters";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { SubscriptionPlanPriceT, SubscriptionPlanT } from "../../types";
import { calculateMonthlyAmount } from "./helpers";
import SubscriptionPlanEntitlements from "./SubscriptionPlanEntitlements";

const width = 337;
const bookingLink = "https://appt.link/meet-with-trendin-SEqgE9V1";

const Wrapper = styled(FlexBox)({
  width: rem(width),
  height: rem(592),
  flexDirection: "column",
  justifyContent: "flex-start",
  borderRadius: rem(10),
  border: `1px solid ${theme.color.greyColor}`,
  backgroundColor: theme.color.whiteColor,

  ["&:hover"]: {
    boxShadow: `0px 26px 40px 0px ${theme.color.boxShadowColor}`,
  },
});

const Content = styled(FlexBox)({
  width: rem(width - 20),
  alignItems: "flex-start",
  flexDirection: "column",
  padding: rem(20),
  borderRadius: rem(10),
});

const TitleWrapper = styled(FlexBox)({
  marginBottom: rem(8),
});

const PriceWrapper = styled(FlexBox)({
  alignItems: "flex-end",
});

const Price = styled(Paragraph)({
  height: rem(24),
  fontWeight: 600,
  fontSize: rem(24),
  paddingRight: rem(10),
});

const VatParagraph = styled(Paragraph)({
  margin: `${rem(8)} 0 ${rem(24)} 0}`,
});

type SubscriptionPlanProps = {
  plan: SubscriptionPlanT;
  chosePlanHandle: (priceId: string) => void;
  period: "month" | "year";
  reccomended?: boolean;
  currency: string;
};

const SubscriptionPlan: FC<SubscriptionPlanProps> = ({
  plan,
  chosePlanHandle,
  period,
  reccomended,
  currency,
}) => {
  const getPrice = (period: "month" | "year"): SubscriptionPlanPriceT => {
    return (
      plan.prices.find(
        (price) => price.period_unit === period && price.id.match(currency),
      ) || plan.prices[0]
    );
  };
  const [selectedPrice, setSelectedPrice] = useState<SubscriptionPlanPriceT>(
    getPrice(period),
  );

  const { t } = useTranslate("brands.subscription_plans");

  const onChooseButtonHandle = (): void => {
    if (plan.id === "enterprise") {
      window.open(bookingLink, "_blank");
    } else {
      chosePlanHandle(selectedPrice.id);
    }
  };

  useEffect(() => {
    setSelectedPrice(getPrice(period));
  }, [period, currency]);

  return (
    <Wrapper>
      <Content>
        <TitleWrapper alignItems="center" gap={rem(8)}>
          <Paragraph
            paragraph={plan.external_name}
            fontWeight={600}
            paragraphSize="small"
          />
        </TitleWrapper>
        <Paragraph
          paragraphSize="small"
          color={theme.color.textGreyColor}
          paragraph={t(`subtitles.${plan.id}`)}
        />
        <Spacer direction="vertical" />
        {plan.id === "enterprise" ? (
          <>
            <Price paragraph={t("entitlements.custom")} />
            <Spacer direction="vertical" size={48} />
          </>
        ) : (
          <>
            <PriceWrapper>
              <Price
                paragraph={`${putSpacesToThousand(
                  calculateMonthlyAmount(
                    selectedPrice.period_unit,
                    selectedPrice.price.amount,
                  )
                    .toFixed(2)
                    .replace(/\.00$/, ""),
                )} ${t(selectedPrice.price.currency.toLowerCase())}`}
              />
              <Paragraph paragraph={t("per_month")} />
            </PriceWrapper>
            <VatParagraph
              paragraph={t("vat_excluded")}
              paragraphSize="small"
              color={theme.color.textGreyColor}
            />
          </>
        )}
        <FlexBox customWidth={CustomWidthE.full}>
          <Button
            type={reccomended ? "primary" : "default"}
            onClick={onChooseButtonHandle}
            style={{ width: "100%" }}
          >
            {t(plan.id !== "enterprise" ? "choose_plan" : "book_call")}
          </Button>
        </FlexBox>
        <Spacer direction="vertical" />
        <SubscriptionPlanEntitlements
          planId={plan.id}
          entitlements={plan.entitlements}
        />
      </Content>
    </Wrapper>
  );
};

export default SubscriptionPlan;
