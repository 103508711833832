import { Radio, Space } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import FilterContainer from "../../components/FilterContainer";

const LastPosted: FC = () => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );
  const { t } = useTranslate("brands.influencer_database.filter.last_posted");

  return (
    <FilterContainer closable={["last_posted"]} t={t}>
      <Radio.Group
        name="last_posted"
        value={searchGlobalQuery.last_posted}
        defaultValue={undefined}
        onChange={(e) =>
          setPartialSearchGlobalQuery({ last_posted: e.target.value })
        }
      >
        <Space direction="vertical">
          <Radio value="">{t("any")}</Radio>
          <Radio value="30">{t("30days")}</Radio>
          <Radio value="90">{t("90days")}</Radio>
          <Radio value="180">{t("180days")}</Radio>
        </Space>
      </Radio.Group>
    </FilterContainer>
  );
};

export default LastPosted;
