import { Checkbox, Space } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import FlexBox from "../../../../../../components/FlexBox";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import FilterContainer from "../../components/FilterContainer";

const AccountType: FC = () => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );
  const { t } = useTranslate("brands.influencer_database.filter.account_type");

  return (
    <FilterContainer closable={["account_type"]} t={t}>
      <FlexBox
        gap={rem(16)}
        alignItems="flex-start"
        flexDirection="column"
        customWidth="100%"
      >
        <Checkbox.Group
          name="ethnicity"
          value={searchGlobalQuery.account_type}
          onChange={(values) =>
            setPartialSearchGlobalQuery({ account_type: values })
          }
        >
          <Space direction="vertical">
            <Checkbox value="1">{t("type1")}</Checkbox>
            <Checkbox value="2">{t("type2")}</Checkbox>
            <Checkbox value="3">{t("type3")}</Checkbox>
          </Space>
        </Checkbox.Group>
      </FlexBox>
    </FilterContainer>
  );
};

export default AccountType;
