import styled from "@emotion/styled";
import { Button } from "antd";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useContext } from "react";
import FlexBox from "../../../../components/FlexBox";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useTranslate } from "../../../../hooks/useTranslate";
import { breakpoints, rem, theme } from "../../../../styling/theme";
import { SearchGlobalQueryContext } from "../../context-providers/SearchGlobalQueryContextProvider";
import { DictionaryUserT, SearchResultT } from "../../types";
import CurrentUsage from "../../ui/CurrentUsage";
import { StyledDivider } from "./components/FilterContainer";
import AccountType from "./filters/global/AccountType";
import Age from "./filters/global/Age";
import AnalyzedProfiles from "./filters/global/AnalyzedProfiles";
import Bio from "./filters/global/Bio";
import ContentSearch from "./filters/global/ContentSearch";
import Countries from "./filters/global/Countries";
import Credibility from "./filters/global/Credibility";
import EngagementRate from "./filters/global/EngagementRate";
import Engagements from "./filters/global/Engagements";
import Ethnicity from "./filters/global/Ethnicity";
import Followers from "./filters/global/Followers";
import FollowersGrowth from "./filters/global/FollowersGrowth";
import Gender from "./filters/global/Gender";
import HasContact from "./filters/global/HasContact";
import Interests from "./filters/global/Interests";
import Language from "./filters/global/Language";
import LastPosted from "./filters/global/LastPosted";
import ReelsPlays from "./filters/global/ReelsPlays";
import Saves from "./filters/global/Saves";
import Shares from "./filters/global/Shares";
import Tags from "./filters/global/Tags";
import TotalLikesGrowth from "./filters/global/TotalLikesGrowth";
import Views from "./filters/global/Views";
import Lists from "./filters/market/Lists";
import Networks from "./filters/Networks";
import { FilterDataT, SearchQueryGlobalT } from "./types";

const Scroll = styled(FlexBox)({
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  overflowY: "scroll",
  padding: rem(20),

  [theme.media.md]: {
    padding: rem(20),
  },
});

const Actions = styled(FlexBox)<{ show: boolean }>(({ show }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.color.greyColor}`,
  padding: rem(10),
  gap: rem(12),

  [theme.media.md]: {
    display: show ? "flex" : "none",
  },
}));

const ShowResultsButton = styled(Button)({
  width: "100%",
});

const CloseButton = styled(Button)({
  width: "100%",
});

type GlobalFilterT = {
  data: FilterDataT | undefined;
  isLoading: boolean;
  usedFilter: boolean;
  globalDefaultWithoutOmits: Omit<SearchQueryGlobalT, "platform" | "sort">;
  listSearchResults: SearchResultT[] | undefined;
  setListSearchResults: (searchResults: SearchResultT[] | undefined) => void;
  setHiddenFilter: Dispatch<SetStateAction<boolean>>;
  setOptions: Dispatch<SetStateAction<DictionaryUserT[]>>;
};

const GlobalFilter: FC<GlobalFilterT> = ({
  data,
  isLoading,
  usedFilter,
  globalDefaultWithoutOmits,
  listSearchResults,
  setListSearchResults,
  setHiddenFilter,
  setOptions,
}) => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );
  const { breakePoint } = useResponsive(breakpoints.md);
  const { t } = useTranslate("brands.influencer_database.filter");

  const onClearHandle = (): void => {
    setPartialSearchGlobalQuery(globalDefaultWithoutOmits);
    setHiddenFilter(true);
  };

  const afterProviderChangeHandle = (): void => {
    setHiddenFilter(true);
    setOptions([]);
  };

  const platformFilters = (): JSX.Element => {
    switch (searchGlobalQuery.platform) {
      case "instagram":
        return (
          <>
            <Engagements />
            <ReelsPlays />
            <Gender />
            <Age />
            <ContentSearch />
            <Credibility />
            <Interests />
            <Bio />
            <FollowersGrowth />
            <LastPosted />
            <Language />
            <Ethnicity />
            <AccountType />
            <HasContact />
          </>
        );
      case "tiktok":
        return (
          <>
            <Views />
            <Engagements />
            <Gender />
            <Age />
            <ContentSearch />
            <Saves />
            <Shares />
            <Bio />
            <FollowersGrowth />
            <TotalLikesGrowth />
            <LastPosted />
            <Language />
            <HasContact />
          </>
        );
      case "youtube":
        return (
          <>
            <Views />
            <Engagements />
            <Gender />
            <Age />
            <ContentSearch />
            <FollowersGrowth />
            <TotalLikesGrowth />
            <LastPosted />
            <Language />
            <HasContact />
          </>
        );
    }
  };

  return (
    <>
      {isLoading || !data ? (
        <PlaceholderLoader type={PlaceholdersTypeE.table_item} />
      ) : (
        <>
          <Scroll>
            <Lists setListSearchResults={setListSearchResults} />
            {!listSearchResults && (
              <>
                <StyledDivider dashed />
                <CurrentUsage
                  entitlementFeature="database-searches"
                  showTreshold={0.8}
                  divider
                />
                <Networks afterChangeHandle={afterProviderChangeHandle} />
                <Tags tags={data?.tags || []} />
                <AnalyzedProfiles />
                <Countries />
                <Followers />
                <EngagementRate />
                {platformFilters()}
              </>
            )}
          </Scroll>
          <Actions show={!listSearchResults && usedFilter}>
            {breakePoint && (
              <ShowResultsButton
                type="primary"
                onClick={() => setHiddenFilter(true)}
              >
                {t("show_results")}
              </ShowResultsButton>
            )}
            <CloseButton onClick={onClearHandle}>
              {t("clear_filters")}
            </CloseButton>
          </Actions>
        </>
      )}
    </>
  );
};

export default GlobalFilter;
