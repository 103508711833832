import { DownOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Dropdown, MenuProps, Segmented, Tag } from "antd";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import LoaderComponent from "../../../../components/Loader";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { designToken } from "../../../../helpers/antDesign";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { getSubscriptionPlans } from "../../data-access/getSubscriptionPlans";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { SubscriptionPlanT } from "../../types";
import { PlanPriceStateT } from "../wizard/WizardContainer";
import SubscriptionPlan from "./SubscriptionPlan";

const czk = require("../../../../assets/payment/czk.png");
const eur = require("../../../../assets/payment/eur.png");

const currencyIcons = {
  CZK: czk,
  EUR: eur,
};

const Wrapper = styled(FlexBox)({
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  backgroundColor: theme.color.whiteColor,
  borderRadius: designToken.borderRadiusLG,
});

const PlansWrapper = styled(FlexBox)({
  alignItems: "flex-end",
  gap: rem(20),

  ["@media screen and (max-width: 1344px)"]: {
    flexDirection: "column",
  },
});

const Img = styled.img({
  width: rem(14),
  height: rem(14),
});

type PeriodT = "month" | "year";
type CurrencyT = "CZK" | "EUR";

type SubscriptionPlansT = {
  planPriceState: PlanPriceStateT | undefined;
  setPlanPriceState: (planPriceState: PlanPriceStateT | undefined) => void;
};

const SubscriptionPlans: FC<SubscriptionPlansT> = ({
  planPriceState,
  setPlanPriceState,
}) => {
  const navigate = useRouterNavigate();
  const [period, setPeriod] = useState<PeriodT>("year");
  const [currency, setCurrency] = useState<CurrencyT>("CZK");

  const { t } = useTranslate("brands.subscription_plans");

  const { data: plans, isLoading } = useQuery(
    QueryKeys.SUBSCRIPTIONS_PLANS,
    () => getSubscriptionPlans(),
  );

  const getPlan = (planId: string): SubscriptionPlanT | void => {
    if (plans && plans.length) {
      const available = plans.filter((plan) => plan.enabled_for_checkout);
      return available.find((plan) => plan.id === planId);
    }
  };

  const litePlan = getPlan("lite");
  const professionalPlan = getPlan("professional");
  const enterprisePlan = getPlan("enterprise");

  const planForPrice = (priceId: string): SubscriptionPlanT | void => {
    if (plans && plans.length) {
      return plans.find((plan) =>
        plan.prices.find((price) => price.id === priceId),
      );
    }
  };

  const chosePlanHandle = (priceId: string): void => {
    const selectedPlan = planForPrice(priceId);
    const selectedPrice = selectedPlan?.prices.find(
      (price) => price.id === priceId,
    );

    if (selectedPrice && selectedPlan) {
      setPlanPriceState({ plan: selectedPlan, price: selectedPrice });
    }
  };

  useEffect(() => {
    planPriceState && navigate(NavigationKeyE.Wizard, "checkout");
  }, [planPriceState]);

  const getPlans = (): JSX.Element => {
    if (isLoading) {
      return <LoaderComponent />;
    }

    return (
      <PlansWrapper alignItems="flex-start">
        {litePlan && (
          <SubscriptionPlan
            plan={litePlan}
            chosePlanHandle={chosePlanHandle}
            period={period}
            currency={currency}
          />
        )}
        {professionalPlan && (
          <SubscriptionPlan
            plan={professionalPlan}
            chosePlanHandle={chosePlanHandle}
            reccomended={true}
            period={period}
            currency={currency}
          />
        )}
        {enterprisePlan && (
          <SubscriptionPlan
            plan={enterprisePlan}
            chosePlanHandle={chosePlanHandle}
            period={period}
            currency={currency}
          />
        )}
      </PlansWrapper>
    );
  };

  const menuItems = [
    { key: "CZK", icon: <Img src={currencyIcons.CZK} />, label: "CZK - Kč" },
    { key: "EUR", icon: <Img src={currencyIcons.EUR} />, label: "EUR - €" },
  ];
  const items: MenuProps["items"] = menuItems;

  const currencyLabel = menuItems.find((item) => item.key === currency)
    ?.label as string;

  return (
    <>
      <Wrapper
        customWidth={CustomWidthE.full}
        alignItems="center"
        flexDirection="column"
      >
        <FlexBox gap={rem(12)} flexDirection="column">
          <Paragraph
            paragraph={t("title")}
            paragraphSize="large"
            fontWeight={600}
          />
        </FlexBox>
        <Spacer size="big" direction="vertical" />
        <FlexBox gap={rem(24)}>
          <Segmented
            options={[
              {
                label: (
                  <FlexBox justifyContent="space-between" gap={rem(10)}>
                    <Paragraph
                      paragraph={t("periods.yearly")}
                      paragraphSize="small"
                    />
                    <Tag
                      color={designToken.cyan1}
                      style={{ marginInlineEnd: "0" }}
                    >
                      <Paragraph
                        paragraph={t("save_up_to")}
                        paragraphSize="small"
                        color={designToken["cyan-7"]}
                      />
                    </Tag>
                  </FlexBox>
                ),
                value: "year",
              },
              { label: t("periods.monthly"), value: "month" },
            ]}
            value={period}
            onChange={(value) => {
              setPeriod(value as PeriodT);
            }}
          />
          <Dropdown
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: [currency],
              onSelect: ({ key }) => setCurrency(key as CurrencyT),
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <FlexBox gap={rem(4)}>
                <Img src={currencyIcons[currency]} />
                <Paragraph
                  paragraph={currencyLabel}
                  color={designToken.colorPrimary}
                  paragraphSize="small"
                />
                <DownOutlined />
              </FlexBox>
            </a>
          </Dropdown>
        </FlexBox>
        <Spacer size="big" direction="vertical" />
        {getPlans()}
      </Wrapper>
    </>
  );
};

export default SubscriptionPlans;
