import axios from "axios";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { DictionaryUserT } from "../types";

export const getDictionaryUsers = async (
  q: string,
  platform: string,
): Promise<DictionaryUserT[]> => {
  return axios
    .get(
      getUrlWithQuery(getApiUrl(ApiRoutesE.DICTIONARY_USERS), { q, platform }),
    )
    .then((res) => res.data.influencers);
};
