import axios from "axios";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { DictionaryLanguageT } from "../types";

export const getDictionaryLanguages = async (): Promise<
  DictionaryLanguageT[]
> => {
  return axios
    .get(getUrlWithQuery(getApiUrl(ApiRoutesE.DICTIONARY_LANGUAGES)))
    .then((res) => res.data.languages);
};
