import {
  InfoCircleOutlined,
  ManOutlined,
  WomanOutlined,
} from "@ant-design/icons";
import { Checkbox } from "antd";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useContext, useMemo } from "react";
import AvatarTile, { AvatarSizeE } from "../../../../../components/AvatarTile";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { designToken } from "../../../../../helpers/antDesign";
import { numberToText } from "../../../../../helpers/formaters";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import Icon from "../../../../../ui/icons/Icon";
import TableColumn from "../../../../../ui/table/TableColumn";
import TableItemWrapper from "../../../../../ui/table/TableItemWrapper";
import { SearchResultT } from "../../../types";
import { AnalyzeContext } from "../context-providers/AnalyzeContextProvider";
import {
  engagementRateColor,
  engagementRateIcon,
} from "../helpers/tierIconsColors";
import { SearchQueryGlobalT } from "../types";
import { demoImageUrls, getRandomElements } from "./fixtures";
import {
  BackgroundImages,
  CredibilityWrapper,
  OudatedTag,
  Section,
  StyledParagraph,
  TableDynamicColumn,
  Thumbnail,
  ThumbnailsColumn,
  Wrapper,
} from "./ui/SearchResultRowUi";

export type PartnershipToRemoveT = {
  id: string;
  removed: boolean;
};

type SearchResultRowT = {
  searchResult: SearchResultT;
  networkProfileIds: string[];
  setNetworkProfileIds: Dispatch<SetStateAction<string[]>>;
  listMode: boolean | undefined;
  searchQuery: SearchQueryGlobalT;
};

const SearchResultRow: FC<SearchResultRowT> = ({
  searchResult,
  networkProfileIds,
  setNetworkProfileIds,
  listMode,
  searchQuery,
}) => {
  const {
    followers,
    fullname,
    url,
    picture,
    username,
    user_id,
    network_profile,
    main_age_group,
    main_gender,
    engagement_rate,
  } = searchResult;
  const isRowAccessible = network_profile ? network_profile.available : false;
  const previewImageUrls = useMemo(
    () => getRandomElements(demoImageUrls(), 3),
    [],
  );

  const { analyze, analyzeButton } = useContext(AnalyzeContext);
  const { t } = useTranslate("brands.influencer_database");

  const formatPercentageValue = (value: number, fixed: number = 1): string => {
    return `${(value * 100).toFixed(fixed)}%`;
  };

  const onCheckboxChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void => {
    event.stopPropagation();

    if (!network_profile) return;

    const value = !networkProfileIds.includes(network_profile.id);

    if (value) {
      setNetworkProfileIds((prev) => [...prev, network_profile.id]);
    } else {
      setNetworkProfileIds((prev) =>
        prev.filter((i) => i !== network_profile.id),
      );
    }
  };

  const onRowClickHandle = (): void => {
    if (!isRowAccessible) return;

    network_profile &&
      window.open(
        `${window.location.origin}/brands/database/${network_profile.id}/overview`,
        "_blank",
      );
  };

  const onAnalyzeClickHandle = (
    e?: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    e?.stopPropagation();
    const handle = searchQuery.platform === "youtube" ? user_id : username;
    analyze({ handle: handle, platform: searchQuery.platform });
  };

  return (
    <TableItemWrapper
      customHeight="auto"
      last={true}
      renderChildren={() => (
        <Wrapper clickable={isRowAccessible} onClick={onRowClickHandle}>
          <Section width="28%">
            {!listMode && (
              <TableColumn
                columnWidth="10%"
                justifyContent="center"
                onClick={(e) => onCheckboxChange(e)}
              >
                <Checkbox
                  disabled={!isRowAccessible}
                  checked={
                    network_profile
                      ? networkProfileIds.includes(network_profile.id)
                      : false
                  }
                />
              </TableColumn>
            )}
            <TableDynamicColumn
              columnWidth={listMode ? "100%" : "90%"}
              justifyContent="flex-start"
              onClick={() => {}}
              padding={`${rem(10)} ${rem(16)} ${rem(10)} ${rem(8)}`}
            >
              <AvatarTile
                handle={username}
                name={fullname}
                imageUrl={picture}
                externalLink={url}
                avatarSize={AvatarSizeE.big}
                network={listMode ? searchQuery.platform : undefined}
                paragraphSize="small"
                handleNameGap={6}
              />
            </TableDynamicColumn>
          </Section>
          <Section width="44%" onClick={() => {}}>
            <TableColumn
              columnWidth="25%"
              justifyContent="center"
              flexDirection="column"
              gap={rem(4)}
            >
              <StyledParagraph
                paragraph={numberToText(followers)}
                fontWeight={600}
              />
              <StyledParagraph
                paragraph={t("table.labels.followers")}
                color={designToken.colorTextQuaternary}
              />
            </TableColumn>
            <TableColumn
              columnWidth="25%"
              justifyContent="center"
              flexDirection="column"
              gap={rem(4)}
            >
              {main_age_group && (
                <>
                  <StyledParagraph
                    align="center"
                    fontWeight={600}
                    paragraph={main_age_group}
                  />
                  <Paragraph
                    paragraph={t("table.labels.main_age_group")}
                    color={designToken.colorTextQuaternary}
                  />
                </>
              )}
            </TableColumn>
            <TableColumn
              columnWidth="25%"
              justifyContent="center"
              flexDirection="column"
              gap={rem(4)}
            >
              {main_gender && (
                <>
                  <FlexBox gap={rem(4)}>
                    {main_gender.code === "FEMALE" ? (
                      <WomanOutlined
                        style={{
                          color: designToken["magenta-6"],
                          fontSize: rem(14),
                        }}
                      />
                    ) : (
                      <ManOutlined
                        style={{
                          color: designToken["geekblue-6"],
                          fontSize: rem(14),
                        }}
                      />
                    )}
                    <StyledParagraph
                      align="center"
                      fontWeight={600}
                      paragraph={formatPercentageValue(main_gender.weight, 0)}
                    />
                  </FlexBox>
                  <StyledParagraph
                    align="center"
                    color={designToken.colorTextQuaternary}
                    paragraph={t(
                      `table.labels.main_gender.${main_gender.code.toLowerCase()}`,
                    )}
                  />
                </>
              )}
            </TableColumn>
            <TableColumn
              columnWidth="25%"
              justifyContent="center"
              flexDirection="column"
              gap={rem(4)}
            >
              {engagement_rate ? (
                <FlexBox gap={rem(4)} flexDirection="column">
                  <CredibilityWrapper
                    color={engagementRateColor(engagement_rate)}
                    gap={rem(4)}
                  >
                    <Icon
                      icon={engagementRateIcon(engagement_rate)}
                      size={14}
                      color={engagementRateColor(engagement_rate)}
                    />
                    <StyledParagraph
                      align="center"
                      fontWeight={600}
                      paragraph={formatPercentageValue(engagement_rate, 1)}
                    />
                  </CredibilityWrapper>
                  <StyledParagraph
                    align="center"
                    color={designToken.colorTextQuaternary}
                    paragraph={t("table.labels.engagement_rate")}
                  />
                </FlexBox>
              ) : (
                <Paragraph paragraph="-" color={theme.color.textGreyColor} />
              )}
            </TableColumn>
          </Section>
          <ThumbnailsColumn
            columnWidth="26%"
            justifyContent="center"
            flexDirection="column"
          >
            {network_profile && network_profile.available ? (
              <>
                <BackgroundImages
                  blurred={
                    network_profile.availability_reason === "report_outdated"
                  }
                >
                  {(network_profile.top_post_urls || [])
                    .slice(0, 3)
                    .map((url, index) => (
                      <Thumbnail src={url} key={index} alt="top post" />
                    ))}
                </BackgroundImages>
                {network_profile.availability_reason === "report_outdated" && (
                  <OudatedTag>
                    <InfoCircleOutlined
                      style={{
                        color: designToken["orange-6"],
                        marginRight: rem(8),
                      }}
                    />
                    <Paragraph
                      paragraph={t("table.analyze.outdated_data")}
                      paragraphSize="small"
                      color={designToken["orange-6"]}
                    />
                    {analyzeButton(
                      network_profile.profile_id,
                      undefined,
                      onAnalyzeClickHandle,
                      "update",
                    )}
                  </OudatedTag>
                )}
              </>
            ) : (
              <>
                <BackgroundImages blurred>
                  {previewImageUrls.map((url, index) => (
                    <Thumbnail src={url} key={index} alt="top post" />
                  ))}
                </BackgroundImages>
                {analyzeButton(
                  searchQuery.platform === "youtube" ? user_id : username,
                  undefined,
                  onAnalyzeClickHandle,
                )}
              </>
            )}
          </ThumbnailsColumn>
        </Wrapper>
      )}
    />
  );
};

export default SearchResultRow;
