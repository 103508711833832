import { Radio, Space } from "antd";
import * as React from "react";
import { FC } from "react";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { GenderTypeT, GlobalGenderTypeT } from "../../types";

type InfluencerGenderT = {
  value: GenderTypeT | GlobalGenderTypeT | undefined;
  setValue: (value: string) => void;
};

const InfluencerGender: FC<InfluencerGenderT> = ({ value, setValue }) => {
  const { t } = useTranslate(
    "brands.influencer_database.filter.gender.influencer",
  );

  return (
    <Radio.Group
      name="influencerGender"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    >
      <Space direction="vertical">
        <Radio value={undefined}>{t("none")}</Radio>
        <Radio value="female">{t("female")}</Radio>
        <Radio value="male">{t("male")}</Radio>
        <Radio value="known">{t("known")}</Radio>
        <Radio value="unknown">{t("unknown")}</Radio>
      </Space>
    </Radio.Group>
  );
};

export default InfluencerGender;
