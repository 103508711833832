import { FunnelPlotOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Modal } from "antd";
import { AxiosError } from "axios";
import { debounce, omit } from "lodash";
import * as React from "react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import Container from "../../../../components/Container";
import FlexBox from "../../../../components/FlexBox";
import SubMenuContainer from "../../../../components/SubMenuContainer";
import {
  getDateForRangeDayFromToday,
  getSystemDate,
} from "../../../../helpers/date";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import {
  defaultGlobalSearchQuery,
  SearchGlobalQueryContext,
} from "../../context-providers/SearchGlobalQueryContextProvider";
import { SubscriptionContext } from "../../context-providers/SubscriptionsContextProvider";
import { ToastContext } from "../../context-providers/ToastContextProvider";
import { getFilterData } from "../../data-access/getFilterData";
import { getSearchResult } from "../../data-access/getSearchResults";
import { updateCurrentUsageMutation } from "../../data-access/mutation/updateCurrentUsageMutation";
import { DictionaryUserT, SearchResultT } from "../../types";
import LimitHeader from "../../ui/LimitHeader";
import GlobalModal from "./components/GlobalModal";
import Search from "./components/Search";
import GlobalFilter from "./GlobalFilter";
import GlobalTable from "./table/GlobalTable";
import { SearchQueryGlobalT, SearchQueryT } from "./types";

const Wrapper = styled(FlexBox)({
  flexDirection: "column",
  marginBottom: rem(16),
  borderRadius: rem(8),
  alignItems: "flex-start",
});

const SearchWrapper = styled(FlexBox)({
  width: "100%",
  justifyContent: "flex-start",
  borderRadius: `${rem(8)} ${rem(8)} 0 0`,
  padding: `${rem(12)} ${rem(12)} 0 ${rem(12)}`,
  backgroundColor: theme.color.whiteColor,
});

export type DatabaseTypeT = "czsk" | "global";

export const defaultSearchQuery: SearchQueryT = {
  age_groups: ["13", "65+"],
  cities: [],
  countries: [],
  credibility_from: undefined,
  credibility_range: undefined,
  engagement_rate_from: undefined,
  engagement_rate_range: undefined,
  followers_gender_from: 51,
  followers_gender_type: undefined,
  followers_interests: [],
  hashtags: [],
  influencer_gender: undefined,
  mentions: [],
  outputs_from: getSystemDate(getDateForRangeDayFromToday(-365)),
  people_count_from: undefined,
  people_count_to: undefined,
  profile_interests: [],
  provider_eq: "instagram",
  tags: [],
  page: 1,
  sort: "people_count:desc",
};

const InfluencerDatabaseContainer: FC = () => {
  const [addNewProfile, setAddNewProfile] = useState<boolean>(false);
  const [listSearchResults, setListSearchResults] = useState<SearchResultT[]>();
  const [options, setOptions] = useState<DictionaryUserT[]>([]);
  const [hiddenFilter, setHiddenFilter] = useState<boolean>(true);

  const { searchGlobalQuery, defaultQuery } = useContext(
    SearchGlobalQueryContext,
  );

  const globalDefaultWithoutOmits = omit(defaultGlobalSearchQuery, [
    "sort",
    "platform",
  ]);

  const { openToast } = useContext(ToastContext);
  const {
    isTrial,
    openTrialModal,
    getFeatureUsage,
    subscription,
    hasMarketAccess,
  } = useContext(SubscriptionContext);
  const usage = getFeatureUsage("database-searches");

  const isOldSubscription =
    !!subscription?.created_at &&
    new Date(subscription.created_at) <= new Date("2025-01-05");
  const [globalModal, setGlobalModal] = useState<boolean>(
    !isTrial &&
      isOldSubscription &&
      localStorage.getItem("globalModalSeen") !== "true",
  );

  const { t } = useTranslate("brands.influencer_database");

  const isFilterUsed = (
    queryOmits: (keyof SearchQueryGlobalT)[],
    defaultQuery: SearchQueryGlobalT = defaultGlobalSearchQuery,
  ): boolean => {
    const queryWithoutOmits = omit(searchGlobalQuery, queryOmits);
    const defaultWithoutOmits = omit(defaultQuery, queryOmits);

    return (
      JSON.stringify(queryWithoutOmits) !== JSON.stringify(defaultWithoutOmits)
    );
  };

  const shouldIncrementSearchCount = (): boolean => {
    const defaultFrom = hasMarketAccess()
      ? { instagram: "5000", tiktok: "10000", youtube: "5000" }[
          searchGlobalQuery.platform
        ]
      : "";

    return (
      isFilterUsed(["platform", "followers"], defaultQuery) ||
      searchGlobalQuery.followers.right_number !== "" ||
      searchGlobalQuery.followers.left_number !== defaultFrom
    );
  };

  const queryClient = useQueryClient();
  const {
    data: searchResultsResponse,
    isLoading: isLoadingGlobal,
    isRefetching: isRefetchingGlobal,
    fetchNextPage: fetchNextPageGlobal,
    hasNextPage: hasNextPageGlobal,
    refetch: searchGlobalRefetch,
  } = useInfiniteQuery(
    [QueryKeys.DATABASE_SEARCH_RESULTS],
    ({ pageParam = 1 }) => {
      return getSearchResult(searchGlobalQuery, pageParam);
    },
    {
      onSuccess: (data) => {
        data.pages[0].accounts.length > 0 &&
          shouldIncrementSearchCount() &&
          updateCurrentUsageMutation(queryClient, "database-searches");
      },
      onError: (error: AxiosError) => {
        error.response?.status === 422 &&
          openToast({ type: "error", message: t("quota_exceeded") });
      },
      getNextPageParam: (lastPage, pages) => {
        const sum = pages
          .map((page) => page.accounts.length)
          .reduce((a, b) => a + b, 0);

        if (lastPage.meta.total > sum) {
          return pages.length + 1;
        }

        return undefined;
      },
    },
  );

  const { data: filterData, isLoading: isFilterLoading } = useQuery(
    QueryKeys.NETWORK_PROFILES_FILTER,
    () => getFilterData(),
  );

  // const { mutate: create, isLoading: isCreateLoading } = useMutation(
  //   () => createNetworkProfile(handle, searchQuery.provider_eq),
  //   {
  //     onSuccess: (data) => {
  //       setHandle("");
  //       setAddNewProfile(false);
  //       if (data.status == "created") {
  //         openToast({
  //           type: "success",
  //           message: t("add_new_profile.notification.success"),
  //         });
  //       } else {
  //         openToast({
  //           type: "error",
  //           message: t("add_new_profile.notification.error"),
  //         });
  //       }
  //     },
  //   },
  // );

  const onAddNewProfileHandle = (): void => {
    isTrial ? openTrialModal() : setAddNewProfile(true);
  };

  const onCloseGlobalModal = (): void => {
    setGlobalModal(false);
    localStorage.setItem("globalModalSeen", "true");
  };

  const searchGlobal = useRef(
    debounce(() => searchGlobalRefetch(), 500),
  ).current;

  useEffect(() => {
    if (usage && usage.used >= usage.quota) {
      openToast({ type: "error", message: t("quota_exceeded") });
    } else {
      searchGlobal();
    }
  }, [searchGlobalQuery]);

  return (
    <>
      {filterData && (
        <>
          <Container
            submenuWidth={312}
            renderSubmenu={(width) => (
              <SubMenuContainer
                width={width}
                floatingButton={{
                  title: t("filter.title"),
                  icon: <FunnelPlotOutlined />,
                  badge: isFilterUsed(["sort", "platform"]),
                }}
                overrideHidden={{
                  value: hiddenFilter,
                  setValue: setHiddenFilter,
                }}
              >
                <GlobalFilter
                  data={filterData}
                  isLoading={isFilterLoading}
                  usedFilter={isFilterUsed(["sort", "platform"])}
                  globalDefaultWithoutOmits={globalDefaultWithoutOmits}
                  listSearchResults={listSearchResults}
                  setListSearchResults={setListSearchResults}
                  setHiddenFilter={setHiddenFilter}
                  setOptions={setOptions}
                />
              </SubMenuContainer>
            )}
          >
            <Wrapper>
              <LimitHeader
                title="Database"
                entitlementFeature="database-reports"
                hideCreate={true}
                onClickHandle={() => {}}
              />
              {!listSearchResults && (
                <SearchWrapper>
                  <FlexBox customWidth={rem(575)}>
                    <Search
                      provider={searchGlobalQuery.platform}
                      options={options}
                      setOptions={setOptions}
                    />
                  </FlexBox>
                </SearchWrapper>
              )}
              <GlobalTable
                data={searchResultsResponse}
                dataIsLoading={isLoadingGlobal || isRefetchingGlobal}
                onAddNewProfileHandle={onAddNewProfileHandle}
                onLoadMoreHandle={fetchNextPageGlobal}
                hasNextPage={hasNextPageGlobal}
                totalsCount={filterData.totals}
                listSearchResults={listSearchResults}
              />
            </Wrapper>

            {/* <Modal
              title={t("add_new_profile.title")}
              open={addNewProfile}
              onOk={() => create()}
              okButtonProps={{
                disabled: handle.length < 3 || isCreateLoading,
                loading: isCreateLoading,
              }}
              onCancel={() => setAddNewProfile(false)}
            >
              <ModalDescription
                paragraph={t("add_new_profile.description")}
                paragraphSize="small"
                color={theme.color.textGreyColor}
              />
              <Input
                addonBefore="@"
                autoFocus
                value={handle}
                placeholder={t("add_new_profile.placeholder")}
                onChange={(e) => setHandle(e.target.value)}
              />
            </Modal> */}

            <Modal
              open={globalModal}
              width={rem(1000)}
              onCancel={onCloseGlobalModal}
              footer={null}
            >
              <GlobalModal onCloseGlobalModal={onCloseGlobalModal} />
            </Modal>
          </Container>
        </>
      )}
    </>
  );
};

export default InfluencerDatabaseContainer;
