import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Tooltip } from "antd";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { SubscriptionEntitlementT } from "../../../types";

const Wrapper = styled(FlexBox)({
  justifyContent: "left",
  paddingBottom: rem(15),
});

const Check = styled(CheckCircleOutlined)({
  marginRight: rem(10),
});

const Cross = styled(CloseCircleOutlined)({
  marginRight: rem(10),
});

const ValueText = styled(Paragraph)({
  paddingRight: rem(5),
  fontWeight: 600,
});

type EntitlementT = {
  entitlement: SubscriptionEntitlementT;
  value: string;
  showValue: boolean;
  translateValue: boolean;
  check?: boolean;
  tooltipValue?: string;
};

const Entitlement: FC<EntitlementT> = ({
  entitlement,
  value,
  showValue,
  translateValue,
  check = true,
  tooltipValue,
}) => {
  const translationKey =
    value && translateValue ? value : entitlement.feature_id;

  const { t } = useTranslate("brands.subscription_plans.entitlements");

  const text = t(translationKey.replaceAll("-", "_"), {
    count: typeof entitlement.value === "number" ? entitlement.value : 0,
  });

  return (
    <Wrapper justifyContent="space-between">
      {check ? (
        <Check style={{ color: designToken["cyan-6"] }} />
      ) : (
        <Cross style={{ color: designToken["red-5"] }} />
      )}
      {showValue && <ValueText paragraph={value} paragraphSize="small" />}
      <FlexBox gap={rem(4)}>
        <Paragraph paragraph={text} paragraphSize="small" />
        {tooltipValue && (
          <Tooltip title={tooltipValue}>
            <QuestionCircleOutlined
              style={{
                fontSize: rem(14),
                color: designToken.colorTextTertiary,
              }}
            />
          </Tooltip>
        )}
      </FlexBox>
    </Wrapper>
  );
};

export default Entitlement;
