import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { useResponsive } from "../../../hooks/useResponsive";
import { useTranslate } from "../../../hooks/useTranslate";
import { breakpoints, rem, theme } from "../../../styling/theme";
import Icon, { IconTypeE } from "../../../ui/icons/Icon";
import { ModalAttributesT } from "../features/approval-request/ApprovalRequestContainer";
import { Link } from "./controls";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${theme.media.lg} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  ${theme.media.maxSm} {
    flex-wrap: wrap-reverse;
  }
`;

const TextWrapper = styled.div<{ leftMargin: boolean }>(({ leftMargin }) => ({
  marginLeft: "0",

  [theme.media.lg]: {
    marginLeft: leftMargin ? rem(32) : "0",
  },
}));

const ImageWrapper = styled.div`
  width: ${rem(212)};
  height: ${rem(160)};
  margin: 0;

  ${theme.media.lg} {
    margin: 0 ${rem(46)} 0 ${rem(86)};
  }
`;

const Text = styled.p`
  font-weight: 400;
  line-height: ${rem(28)};
  font-size: ${rem(14)};
  margin: ${rem(12)} 0 0;
  color: ${theme.color.bwBlack};
  opacity: 0.6;

  ${theme.media.lg} {
    display: block;
    margin: 0 0 ${rem(33)};
  }
`;

const Image = styled.div<{ youtubeId: string }>(({ youtubeId }) => ({
  backgroundImage: `url(https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg)`,
  backgroundPosition: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: rem(212),
  height: rem(160),
  cursor: "pointer",
  borderRadius: rem(8),
}));

interface InfoCardContent {
  title: string;
  text: string;
  youtubeIds: {
    desktop?: string;
    portrait?: string;
  };
  setModalAttributes: Dispatch<SetStateAction<ModalAttributesT | undefined>>;
  leftMargin?: boolean;
}

const AccordionIframe: FC<InfoCardContent> = ({
  title,
  text,
  youtubeIds,
  setModalAttributes,
  leftMargin = true,
}) => {
  const { t } = useTranslate("influencer_root.approval_request.tutorials");
  const { desktop, portrait } = youtubeIds;
  const { breakePoint } = useResponsive(breakpoints.md);
  const youtubeId = breakePoint ? portrait : desktop;
  const fileVersion = breakePoint ? "mobile" : "desktop";

  const onClickHandle = (): void => {
    setModalAttributes({
      iframeSrc: `https://www.youtube.com/embed/${youtubeId}?autoplay=1`,
      title: title,
    });
  };

  return (
    <Wrapper>
      <TextWrapper leftMargin={leftMargin}>
        <Text>{text}</Text>
        <Link
          href="https://reportee-production.s3.eu-central-1.amazonaws.com/public/influencer-tutorial.pdf"
          download
        >
          {t("download_pdf")}
        </Link>
      </TextWrapper>
      {youtubeId && (
        <ImageWrapper>
          <Image youtubeId={youtubeId} onClick={onClickHandle}>
            <Icon
              icon={IconTypeE.play}
              size={33}
              color={theme.color.yellowColor}
            />
          </Image>
        </ImageWrapper>
      )}
    </Wrapper>
  );
};

export default AccordionIframe;
