import styled from "@emotion/styled";
import { Select } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import FlexBox, { CustomWidthE } from "../../../../../../components/FlexBox";
import Paragraph from "../../../../../../components/Paragraph";
import { designToken } from "../../../../../../helpers/antDesign";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import FilterContainer from "../../components/FilterContainer";

const Dash = styled(Paragraph)({
  color: designToken.colorTextPlaceholder,
});

type OptionValueT = {
  value: string;
  label: string;
};

const ReelsPlays: FC = () => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );

  const {
    reels_plays: { left_number, right_number },
  } = searchGlobalQuery;

  const { t } = useTranslate("brands.influencer_database.filter.reels_plays");

  const options = (key: "from" | "to"): OptionValueT[] => {
    return [
      { value: "", label: t(key) },
      { value: "1000", label: "1k" },
      { value: "5000", label: "5k" },
      { value: "10000", label: "10k" },
      { value: "50000", label: "50k" },
      { value: "100000", label: "100k" },
      { value: "500000", label: "500k" },
      { value: "1000000", label: "1M+" },
    ];
  };

  const setValue = (
    value: string,
    type: "left_number" | "right_number",
  ): void => {
    const left = type === "left_number" ? value : left_number;
    const right = type === "right_number" ? value : right_number;

    setPartialSearchGlobalQuery({
      reels_plays: { left_number: left, right_number: right },
    });
  };

  return (
    <FilterContainer t={t}>
      <FlexBox customWidth={CustomWidthE.full} gap={rem(8)}>
        <Select
          style={{ width: "100%" }}
          placeholder={t("from")}
          options={options("from")}
          value={left_number}
          onChange={(value) => setValue(value, "left_number")}
        />
        <Dash paragraph={"-"} paragraphSize="small" />
        <Select
          style={{ width: "100%" }}
          placeholder={t("to")}
          options={options("to")}
          value={right_number}
          onChange={(value) => setValue(value, "right_number")}
        />
      </FlexBox>
    </FilterContainer>
  );
};

export default ReelsPlays;
