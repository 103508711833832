export const containerSizes = {
  mobileMenuWidth: 285,
  miniContainerWidth: 500,
  smallContainerWidth: 920,
  baseContainerWidth: 1330,
  bigContainerWidth: 1520,
  monsterContainerWidth: 1840,
};

export const breakpoints = {
  xs: 330,
  s: 450,
  mi: 520,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1440,
  xxl: 1920,
};

export const MOBILE_VERSION_BREAKPOINT = breakpoints.mi;
export const TABLET_VERSION_BREAKPOINT = breakpoints.md;

export const media = {
  xxl: `@media screen and (min-width: ${breakpoints.xxl}px)`,
  xl: `@media screen and (min-width: ${breakpoints.xl}px)`,
  lg: `@media screen and (min-width: ${breakpoints.lg}px)`,
  md: `@media screen and (min-width: ${breakpoints.md}px)`,
  sm: `@media screen and (min-width: ${breakpoints.sm}px)`,
  xs: `@media screen and (min-width: ${breakpoints.xs}px)`,
  maxXXl: `@media screen and (max-width: ${breakpoints.xxl - 1}px)`,
  maxXl: `@media screen and (max-width: ${breakpoints.xl - 1}px)`,
  maxLg: `@media screen and (max-width: ${breakpoints.lg - 1}px)`,
  maxMd: `@media screen and (max-width: ${breakpoints.md - 1}px)`,
  maxSm: `@media screen and (max-width: ${breakpoints.sm - 1}px)`,
  maxMi: `@media screen and (max-width: ${breakpoints.mi - 1}px)`,
  maxS: `@media screen and (max-width: ${breakpoints.s - 1}px)`,
  maxXs: `@media screen and (max-width: ${breakpoints.xs - 1}px)`,
};

export const color = {
  whiteColor: "#FFFFFF",
  blackColor: "#000000",
  backgroundColor: "#F7F7F9",
  borderColor: "#F0F0F0",
  boxShadowColor: "rgba(0, 0, 0, 0.0991565)",
  textColor: "#333333",
  textGreyColor: "#828282",
  textLightGreyColor: "#BDBDBD",
  textGreyDarkColor: "#4F4F4F",
  overlayColor: "rgba(34, 34, 34, .5)",
  bwBlack: "#02061F",
  primaryColor: "#523FC2",
  secondaryColor: "#20C5C8",
  // darkPrimaryColor: "#1C309A",
  darkPrimaryColor: "#3846A2",
  lightPrimaryColor: "#9FAADC",
  primaryBackgroundColor: "#485BBD0F",
  lighterBlueColor: "#EEF4F9",
  facebookBlueColor: "#1877F2",
  facebookBlueHoverColor: "#166fe5",
  successColor: "#27AE60",
  successColorLight: "#ECFFF5",
  errorColor: "#F5244A",
  errorSecondaryColor: "#F86782",
  errorHoverColor: "#c51837",
  errorLightColor: "#FEE9ED",
  errorLightHoverColor: "#F9D6DD",
  errorBackgroundColor: "#fff4f6",
  iconGreyColor: "#757575",
  yellowColor: "#FFCA0A",
  yellowHoverColor: "#FFAC0A",
  yellowPressedColor: "#FF8E0A",
  yellowLightColor: "#FFE999",
  yellowLightHoverColor: "#FFFBEC",
  greyColor: "#E0E0E0",
  greyLightColor: "#F2F2F2",
  remainingColor: "#EDF2FC",
};

type ColorT = {
  whiteColor: string;
  blackColor: string;
  backgroundColor: string;
  borderColor: string;
  boxShadowColor: string;
  textColor: string;
  textGreyColor: string;
  textLightGreyColor: string;
  textGreyDarkColor: string;
  overlayColor: string;
  bwBlack: string;
  primaryColor: string;
  darkPrimaryColor: string;
  lightPrimaryColor: string;
  primaryBackgroundColor: string;
  lighterBlueColor: string;
  facebookBlueColor: string;
  facebookBlueHoverColor: string;
  successColor: string;
  successColorLight: string;
  errorColor: string;
  errorSecondaryColor: string;
  errorHoverColor: string;
  errorLightColor: string;
  errorLightHoverColor: string;
  errorBackgroundColor: string;
  iconGreyColor: string;
  yellowColor: string;
  yellowHoverColor: string;
  yellowPressedColor: string;
  yellowLightColor: string;
  yellowLightHoverColor: string;
  greyColor: string;
  greyLightColor: string;
  remainingColor: string;
  secondaryColor: string;
};

type MediaT = {
  xxl: string;
  xl: string;
  lg: string;
  md: string;
  sm: string;
  xs: string;
  maxXXl: string;
  maxXl: string;
  maxLg: string;
  maxMd: string;
  maxSm: string;
  maxMi: string;
  maxS: string;
  maxXs: string;
};

type ContainerSizesT = {
  mobileMenuWidth: number;
  miniContainerWidth: number;
  smallContainerWidth: number;
  baseContainerWidth: number;
  bigContainerWidth: number;
  monsterContainerWidth: number;
};

const timing = {
  default: "cubic-bezier(0.22, 1, 0.36, 1)",
};

type TimingT = {
  default: string;
};

type getRGBaColorT = (color: string, opacity?: number) => string;
export const getRGBaColor: getRGBaColorT = (color, opacity = 0.5) => {
  switch (color) {
    case theme.color.greyLightColor:
      return `rgba(242, 242, 242, ${opacity})`;

    case theme.color.yellowLightHoverColor:
      return `rgba(255, 251, 236, ${opacity})`;

    case theme.color.successColorLight:
      return `rgba(236, 255, 245, ${opacity})`;

    default:
      return `rgba(255, 255, 255, ${opacity})`;
  }
};

type hexToRgbT = (hex: string, alpha: number) => string;
export const hexToRgb: hexToRgbT = (hex, alpha) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
};

const fontSizeBase = 16;
type RemT = (val: number) => string;
export const rem: RemT = (val: number) => {
  return `${Math.round((val / fontSizeBase) * 10000) / 10000}rem`;
};

type ThemeInterface = {
  containerSizes: ContainerSizesT;
  color: ColorT;
  media: MediaT;
  timing: TimingT;
  rem: (val: number) => string;
};

export const theme: ThemeInterface = {
  containerSizes,
  color,
  media,
  timing,
  rem,
};

export const HEADER_HEIGHT = 72;
