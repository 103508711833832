import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { InfluencerProfileT } from "../features/influencer-database-detail/types";

export const getNetworkProfile = async (
  id: string,
): Promise<InfluencerProfileT> => {
  return axios
    .get(getApiUrl(ApiRoutesE.NETWORK_PROFILE_DETAIL, id))
    .then((res) => res.data.network_profile);
};
