import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import {
  InfluencerReportWithNetworkProfileT,
  NetworkProviderT,
} from "../../types";

export const createInfluencerReport = async (
  handle: string,
  provider: NetworkProviderT,
): Promise<InfluencerReportWithNetworkProfileT> => {
  return axios
    .post(getApiUrl(ApiRoutesE.INFLUENCER_REPORTS), {
      influencer_report: {
        handle: handle,
        provider: provider,
      },
    })
    .then((res) => res.data.influencer_report);
};
