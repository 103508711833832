import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button } from "antd";
import * as React from "react";
import { FC, useContext, useState } from "react";
import { InfiniteData } from "react-query";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import NoResults from "../../../../../components/NoResults";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../features/Placeholders/PlaceholderLoader";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { getRGBaColor, rem, theme } from "../../../../../styling/theme";
import TableColumn from "../../../../../ui/table/TableColumn";
import TableContentWrapper from "../../../../../ui/table/TableContentWrapper";
import { SearchGlobalQueryContext } from "../../../context-providers/SearchGlobalQueryContextProvider";
import { SubscriptionContext } from "../../../context-providers/SubscriptionsContextProvider";
import { SearchResultResponseT, SearchResultT } from "../../../types";
import { FilterDataTotalsT, SearchQueryGlobalSortFieldT } from "../types";
import SearchResultRow from "./SearchResultRow";
import TableHeader from "./TableHeader";

const rgbaYellow = getRGBaColor(theme.color.yellowColor, 0.25);

const LoaderContainer = styled(FlexBox)({
  height: rem(4),
  width: "100%",
});

const Loader = styled.div({
  width: "100%",
  height: "4px",
  display: "inline-block",
  backgroundColor: theme.color.primaryColor,
  backgroundImage: `linear-gradient(45deg, ${rgbaYellow} 25%, transparent 25%, transparent 50%, ${rgbaYellow} 50%, ${rgbaYellow} 75%, transparent 75%, transparent)`,
  fontSize: "30px",
  backgroundSize: "1em 1em",
  boxSizing: "border-box",
  animation: "barStripe 1s linear infinite",
});

const StickyHeader = styled(FlexBox)({
  flexDirection: "column",
  width: "100%",
  position: "sticky",
  top: 0,
  zIndex: 2,
  backgroundColor: theme.color.whiteColor,

  [theme.media.maxSm]: {
    borderRadius: `0 0 ${rem(8)} ${rem(8)}`,
    borderBottom: `1px solid ${theme.color.greyLightColor}`,
    top: rem(64),
  },
});

const LoadMoreTableBottom = styled(TableColumn)({
  width: "100%",
  justifyContent: "center",
  cursor: "pointer",
  borderBottomLeftRadius: rem(8),
  borderBottomRightRadius: rem(8),
  backgroundColor: theme.color.whiteColor,
  borderRadius: `0 0 ${rem(8)} ${rem(8)}`,
  borderTop: `1px solid ${theme.color.greyLightColor}`,
  gap: rem(5),

  ":hover": {
    backgroundColor: theme.color.greyLightColor,
  },

  [theme.media.maxSm]: {
    borderTop: "none",
    borderRadius: rem(8),
  },
});

const RowsWrapper = styled(FlexBox)({
  width: "100%",
  flexDirection: "column",
  backgroundColor: "#F7F7F9",
});

type GlobalTableT = {
  data?: InfiniteData<SearchResultResponseT>;
  dataIsLoading: boolean;
  onAddNewProfileHandle: () => void;
  onLoadMoreHandle: () => void;
  hasNextPage: boolean | undefined;
  totalsCount: FilterDataTotalsT;
  listSearchResults?: SearchResultT[];
};

const GlobalTable: FC<GlobalTableT> = ({
  data,
  dataIsLoading,
  onAddNewProfileHandle = () => {},
  onLoadMoreHandle = () => {},
  hasNextPage,
  totalsCount,
  listSearchResults,
}) => {
  const [networkProfileIds, setNetworkProfileIds] = useState<string[]>([]);

  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );
  const { getFeatureUsage } = useContext(SubscriptionContext);
  const usage = getFeatureUsage("database-searches");
  const overLimit = usage && usage.used >= usage.quota;

  const sortingOptions =
    searchGlobalQuery.platform === "instagram"
      ? ["engagements", "followers"]
      : ["engagements", "followers", "views"];

  const { t } = useTranslate("brands.influencer_database.table");

  const profileRows = (profiles: SearchResultT[]): JSX.Element => {
    return (
      <>
        {profiles.map((profile, index) => (
          <SearchResultRow
            key={index}
            searchResult={profile}
            networkProfileIds={networkProfileIds}
            setNetworkProfileIds={setNetworkProfileIds}
            listMode={!!listSearchResults}
            searchQuery={searchGlobalQuery}
          />
        ))}
      </>
    );
  };

  const getTableContent = (): JSX.Element => {
    if (listSearchResults) {
      return profileRows(listSearchResults);
    }

    if (data && data.pages[0].accounts.length > 0) {
      return (
        <>
          {data &&
            data.pages.map((group, i) => (
              <RowsWrapper key={i}>{profileRows(group.accounts)}</RowsWrapper>
            ))}
          {hasNextPage && !overLimit && (
            <LoadMoreTableBottom onClick={onLoadMoreHandle}>
              <DownOutlined rev="" />
              {t("load_more")}
            </LoadMoreTableBottom>
          )}
        </>
      );
    }

    if (dataIsLoading) {
      return (
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          <PlaceholderLoader
            type={PlaceholdersTypeE.table_item}
            count={5}
            direction="column"
          />
        </FlexBox>
      );
    }

    return (
      <TableContentWrapper blocksAreRows={true}>
        <NoResults illustrationSize={350} message={t("no_results.heading")} />
        <Spacer direction="vertical" />
        <Paragraph align="center" paragraph={t("no_results.paragraph")} />
        <Spacer direction="vertical" />
        <Button
          type="primary"
          icon={<PlusOutlined rev="" />}
          onClick={onAddNewProfileHandle}
        >
          {t("no_results.add")}
        </Button>
      </TableContentWrapper>
    );
  };

  const setSort = (value: string): void => {
    setPartialSearchGlobalQuery({
      sort: {
        field: value as SearchQueryGlobalSortFieldT,
        direction: "desc",
      },
    });
  };

  return (
    <>
      <StickyHeader>
        <TableHeader
          currentCount={(data && data.pages[0].meta.total) || 0}
          totalCount={0}
          sortingOptions={sortingOptions}
          networkProfileIds={networkProfileIds}
          setNetworkProfileIds={setNetworkProfileIds}
          listProfilesCount={listSearchResults?.length || 0}
          currentSort={searchGlobalQuery.sort.field}
          setSort={(value) => setSort(value)}
        />
        <LoaderContainer>{dataIsLoading && <Loader />}</LoaderContainer>
      </StickyHeader>

      {getTableContent()}
    </>
  );
};

export default GlobalTable;
