import styled from "@emotion/styled";
import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import BlueLink from "../../../../components/BlueLink";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import CheckBox from "../../../../ui/CheckBox";
import Icon, { IconTypeE } from "../../../../ui/icons/Icon";
import {
  InfluencerNavigationContext,
  isInfluencerNavigationDetailItem,
} from "../../context-providers/InfluencerNavigationContextProvider";
import { updateApprovalRequest } from "../../data-access/updateApprovalRequest";
import AccordionIframe from "../../ui/AccordionIframe";
import Faq from "../../ui/Faq";
import Footer from "../../ui/Footer";
import InfoHeader from "../../ui/InfoHeader";
import ApprovalInfo from "./ApprovalInfo";
import { ModalAttributesT } from "./ApprovalRequestContainer";

const DisableOverlay = styled(FlexBox)({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  backgroundColor: theme.color.whiteColor,
  opacity: 0.5,
});

const ButtonWrapper = styled(FlexBox)({
  gap: rem(8),
  whiteSpace: "nowrap",
});

const AgreementWrapper = styled(FlexBox)({
  [theme.media.maxSm]: {
    marginBottom: rem(8),
  },
});

type FacebookApprovalT = {
  approvalRequestId: string;
  mentions: string[];
  handle: string;
  disableAllow: boolean;
  email: string;
  company_name: string;
  resetTacCheckBox: boolean;
  url?: string;
  setModalAttributes: Dispatch<SetStateAction<ModalAttributesT | undefined>>;
};

const FacebookApproval: FC<FacebookApprovalT> = ({
  approvalRequestId,
  mentions,
  handle,
  disableAllow,
  email,
  company_name,
  resetTacCheckBox,
  url,
  setModalAttributes,
}) => {
  const { t } = useTranslate("influencer_root.approval_request");
  const { navigationState, setNavigationState } = useContext(
    InfluencerNavigationContext,
  );
  const { session } = useContext(ApplicationContext);
  const navigationDetailState =
    navigationState && isInfluencerNavigationDetailItem(navigationState)
      ? navigationState
      : undefined;

  const [tac, setTac] = useState(false);

  const mentionAsString =
    mentions.length > 0 ? `@${mentions.join(", @")}` : "-";

  const {
    data,
    refetch: authorizationCall,
    isLoading,
  } = useQuery(
    QueryKeys.APPROVAL,
    () =>
      updateApprovalRequest(
        getApiUrl(ApiRoutesE.APPROVAL_REQUESTS_ITEM, approvalRequestId),
        {
          approval_request: {
            approved_mentions: mentions,
            tac_approval_request: true,
          },
        },
      ),
    { enabled: false },
  );

  const accordionItems = [
    {
      header: <InfoHeader>{t("approval.faq.item1.title")}</InfoHeader>,
      content: (
        <AccordionIframe
          title={t("approval.faq.item1.title")}
          youtubeIds={{
            desktop: t("approval.faq.item1.youtube"),
            portrait: t("approval.faq.item1.youtube"),
          }}
          text={t("approval.faq.item1.text")}
          setModalAttributes={setModalAttributes}
          leftMargin={false}
        />
      ),
    },
  ];

  const disabledFacebookButton = !tac || disableAllow || isLoading;

  useEffect(() => {
    // when request from FB approval window is a error
    // tac checkbox is automatically switch to the ucnchecked state
    if (tac) {
      setTac(false);
    }
  }, [resetTacCheckBox]);

  useEffect(() => {
    if (data) {
      window.location.reload();
    }
  }, [data]);

  const onClickHandle = (): void => {
    if (session?.role === "brand") {
      alert(t("approval.brand_alert"));

      return;
    }

    if (!url) {
      authorizationCall();

      return;
    }

    window.location.href = url;
  };

  const goBackHandle = (): void => {
    if (navigationDetailState) {
      setNavigationState({ ...navigationDetailState, step: "tutorials" });
    }
  };

  return (
    <>
      <ApprovalInfo
        title={t("approval.box.title")}
        content={t("approval.box.text", {
          name: company_name,
          mentions: mentionAsString,
        })}
        image="ipad"
      />
      <Faq data={accordionItems} variant="secondary" />
      <Footer checkbox={true}>
        <AgreementWrapper
          onClick={() =>
            disableAllow ? null : setTac((prevState) => !prevState)
          }
        >
          <CheckBox disabled={disableAllow} active={tac && !disableAllow} />
          <Spacer size="small" />
          <FlexBox
            flexWrap="wrap"
            justifyContent="flex-start"
            cursor={disableAllow ? "auto" : "pointer"}
          >
            <Paragraph paragraph={t("footer.agreement")} />
            <Spacer size="micro" />
            <BlueLink
              text={t("footer.tac")}
              href="/tac"
              target="_blank"
              marginTop={"0"}
              paragraphSize="default"
            />
            <Spacer size="micro" />
            <Paragraph paragraph={t("footer.and")} />
            <Spacer size="micro" />
            <BlueLink
              text={t("footer.privacy")}
              href="/privacy"
              target="_blank"
              marginTop={"0"}
              paragraphSize="default"
            />
          </FlexBox>
        </AgreementWrapper>
        <ButtonWrapper justifyContent="stretch">
          <Button
            onClick={goBackHandle}
            type={ButtonTypeE.outline}
            customWidth={CustomWidthE.full}
            size={ButtonSizeE.small}
          >
            {t("footer.back")}
          </Button>
          <Button
            type={ButtonTypeE.facebook}
            customWidth={CustomWidthE.full}
            onClick={onClickHandle}
            disabled={disabledFacebookButton}
            size={ButtonSizeE.small}
          >
            <Icon
              size="big"
              icon={IconTypeE.facebookLogo}
              color={
                disabledFacebookButton
                  ? theme.color.greyColor
                  : theme.color.facebookBlueColor
              }
            />
            <Spacer size="mini" />
            {t("approve_connect_button")}
          </Button>
        </ButtonWrapper>
      </Footer>
      {/* {disableAllow && <DisableOverlay />} */}
    </>
  );
};

export default FacebookApproval;
