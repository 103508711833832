import {
  GlobalOutlined,
  RiseOutlined,
  SyncOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Progress } from "antd";
import * as React from "react";
import { FC } from "react";
import BlueLink from "../../../../components/BlueLink";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { designToken } from "../../../../helpers/antDesign";
import { useTranslate } from "../../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../../styling/theme";
import {
  SubscriptionCurrentUsageT,
  SubscriptionEntitlementFeaturesT,
} from "../../../types";

const Wrapper = styled(FlexBox)({
  width: "100%",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: hexToRgb(designToken["blue-1"], 0.2),
  padding: `${rem(20)} ${rem(16)}`,
  borderRadius: designToken.borderRadiusSM,
});

type UsageItemT = {
  usage: SubscriptionCurrentUsageT;
  action?: { title: string; onClickhandle: () => void };
};

const UsageItem: FC<UsageItemT> = ({ usage, action }) => {
  const { feature_id, used, quota } = usage;

  const percentage = quota > 0 ? used / (quota / 100) : 100;

  const { t } = useTranslate("brands.manage_subscription");

  const icon = (
    featureId: SubscriptionEntitlementFeaturesT,
  ): JSX.Element | void => {
    switch (featureId) {
      case "users":
        return <UsergroupAddOutlined />;
      case "competitive-analyses":
        return <RiseOutlined />;
      case "reporting-influencers":
        return <UserOutlined />;
      case "overlap-analyses":
        return <SyncOutlined />;
      case "database-reports":
        return <GlobalOutlined />;
    }
  };

  return (
    <Wrapper>
      <FlexBox gap={rem(8)}>
        {icon(feature_id)}
        <Paragraph paragraph={t(`usages.${feature_id.replace(/-/g, "_")}`)} />
      </FlexBox>
      <Progress
        percent={percentage}
        showInfo={false}
        status={
          quota.toString() === "Unlimited"
            ? undefined
            : percentage >= 100
              ? "exception"
              : "normal"
        }
      />
      <FlexBox
        customWidth={CustomWidthE.full}
        justifyContent="space-between"
        gap={rem(4)}
      >
        <FlexBox gap={rem(4)}>
          <Paragraph
            paragraph={used.toString()}
            color={used >= quota ? designToken.red4 : theme.color.blackColor}
            fontWeight={600}
          />
          <Paragraph
            paragraph={`/ ${quota} ${t("used")}`}
            color={designToken.colorTextTertiary}
          />
        </FlexBox>
        {action && (
          <BlueLink
            type="grey"
            text={action.title}
            onClick={action.onClickhandle}
          />
        )}
      </FlexBox>
    </Wrapper>
  );
};

export default UsageItem;
