import styled, { CSSObject } from "@emotion/styled";
import _ from "lodash";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../styling/theme";
import InnerHTML from "./InnerHTML";

type AlignStyleT = "right" | "left" | "center" | "inherit";
type DisplayStyleT = "block" | "inline-block";

type ParagraphStyleT = {
  paragraphSize: ParagraphSizeT;
  fontWeight: number;
  color: string;
  align: AlignStyleT;
  display: DisplayStyleT;
  truncate: boolean;
  wordBreak: "break-word" | "normal";
  cursor: "pointer" | "auto" | "inherit";
};

const StyledParagraph = styled.p<ParagraphStyleT>(
  ({
    paragraphSize,
    color,
    fontWeight,
    align,
    display,
    truncate,
    wordBreak,
    cursor,
  }) => ({
    ...getParagraphStyles(paragraphSize),
    color,
    fontWeight,
    marginTop: 0,
    marginBottom: 0,
    textAlign: align,
    width: "auto",
    wordBreak,
    display,
    cursor,
    ...(truncate &&
      ({
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      } as CSSObject)),
  }),
);

type getParagraphStylesT = (type: ParagraphSizeT) => CSSObject;
const getParagraphStyles: getParagraphStylesT = (type) => {
  switch (type) {
    case "large":
      return {
        fontSize: rem(20),
      };
    case "big":
      return {
        fontSize: rem(18),
      };
    case "default":
      return {
        fontSize: rem(16),
      };
    case "small":
      return {
        fontSize: rem(14),
      };
    case "micro":
      return {
        fontSize: rem(12),
      };

    default:
      return {};
  }
};

export type ParagraphSizeT = "large" | "default" | "big" | "small" | "micro";
export type ParagraphFontWeightT = 400 | 500 | 600 | 700;

type ParagraphT = {
  paragraph: string | JSX.Element;
  paragraphSize?: ParagraphSizeT;
  fontWeight?: ParagraphFontWeightT;
  color?: string;
  align?: AlignStyleT;
  display?: DisplayStyleT;
  className?: string;
  truncate?: number | "css";
  wordBreak?: "break-word" | "normal";
  cursor?: "pointer" | "auto" | "inherit";
};

const Paragraph: FC<ParagraphT> = ({
  paragraph,
  paragraphSize = "default",
  fontWeight = 400,
  color = theme.color.textColor,
  align = "left",
  display = "block",
  className,
  truncate,
  wordBreak = "break-word",
  cursor = "inherit",
}) => {
  return (
    <StyledParagraph
      paragraphSize={paragraphSize}
      fontWeight={fontWeight}
      color={color}
      align={align}
      display={display}
      className={className}
      truncate={truncate === "css"}
      wordBreak={wordBreak}
      cursor={cursor}
    >
      {typeof paragraph === "string" ? (
        <InnerHTML
          content={
            truncate && typeof truncate === "number"
              ? _.truncate(paragraph, {
                  length: truncate,
                })
              : paragraph
          }
        />
      ) : (
        paragraph
      )}
    </StyledParagraph>
  );
};

export default Paragraph;
