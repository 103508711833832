import styled from "@emotion/styled";
import { Skeleton } from "antd";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../components/FlexBox";
import { designToken } from "../../../../../helpers/antDesign";
import { rem, theme } from "../../../../../styling/theme";

const Wrapper = styled(FlexBox)({
  width: "100%",
  backgroundColor: theme.color.whiteColor,
  padding: rem(16),
  flexDirection: "column",
  gap: rem(24),
});

const BoxWrapper = styled(FlexBox)({
  width: "100%",
  height: rem(165),
  flexDirection: "row",
  gap: rem(12),
  justifyContent: "space-between",
  overflow: "hidden",
});

const CardsWrapper = styled(FlexBox)({
  width: "100%",
  flexDirection: "row",
  gap: rem(12),
  justifyContent: "space-between",
  flexWrap: "wrap",
});

const Card = styled(FlexBox)({
  width: "32%",
  border: `1px solid ${designToken.colorBorderSecondary}`,
  padding: rem(16),
  borderRadius: rem(8),
  flexDirection: "column",
});

const SkeletonNodeWrapper = styled(FlexBox)({
  width: "100%",

  ".ant-skeleton-element": {
    width: "100%",
  },
});

const SkeletonPlaceholder: FC = () => {
  return (
    <Wrapper>
      <BoxWrapper>
        <Skeleton.Node active style={{ width: rem(200), height: rem(160) }}>
          {false}
        </Skeleton.Node>
        <Skeleton.Node active style={{ width: rem(200), height: rem(160) }}>
          {false}
        </Skeleton.Node>
        <Skeleton.Node active style={{ width: rem(200), height: rem(160) }}>
          {false}
        </Skeleton.Node>
        <Skeleton.Node active style={{ width: rem(200), height: rem(160) }}>
          {false}
        </Skeleton.Node>
        <Skeleton.Node active style={{ width: rem(200), height: rem(160) }}>
          {false}
        </Skeleton.Node>
      </BoxWrapper>
      <CardsWrapper>
        <Card>
          <Skeleton paragraph={{ rows: 1 }} active />
          <SkeletonNodeWrapper>
            <Skeleton.Image
              active
              style={{ width: "100%", height: rem(218) }}
            />
          </SkeletonNodeWrapper>
        </Card>
        <Card>
          <Skeleton paragraph={{ rows: 1 }} active />
          <SkeletonNodeWrapper>
            <Skeleton.Image
              active
              style={{ width: "100%", height: rem(218) }}
            />
          </SkeletonNodeWrapper>
        </Card>
        <Card>
          <Skeleton paragraph={{ rows: 1 }} active />
          <SkeletonNodeWrapper>
            <Skeleton.Image
              active
              style={{ width: "100%", height: rem(218) }}
            />
          </SkeletonNodeWrapper>
        </Card>
        <Card>
          <Skeleton paragraph={{ rows: 1 }} active />
          <SkeletonNodeWrapper>
            <Skeleton.Image
              active
              style={{ width: "100%", height: rem(218) }}
            />
          </SkeletonNodeWrapper>
        </Card>
        <Card>
          <Skeleton paragraph={{ rows: 1 }} active />
          <SkeletonNodeWrapper>
            <Skeleton.Image
              active
              style={{ width: "100%", height: rem(218) }}
            />
          </SkeletonNodeWrapper>
        </Card>
        <Card>
          <Skeleton paragraph={{ rows: 1 }} active />
          <SkeletonNodeWrapper>
            <Skeleton.Image
              active
              style={{ width: "100%", height: rem(218) }}
            />
          </SkeletonNodeWrapper>
        </Card>
      </CardsWrapper>
    </Wrapper>
  );
};

export default SkeletonPlaceholder;
