import styled from "@emotion/styled";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import BlueLink from "../../../../../components/BlueLink";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../../components/Heading";
import Paragraph from "../../../../../components/Paragraph";
import Spacer from "../../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../features/Placeholders/PlaceholderLoader";
import { MixpanelEventT } from "../../../../../helpers/trackEvent";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import AvatarWithCircles from "../../../../../ui/AvatarWithCircles";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import { NavigationKeyE, useRouterNavigate } from "../../../router";
import { InfluencerProfileT } from "../types";
import Actions from "./Actions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
);

const HandleWrapper = styled(FlexBox)({
  display: "flex",

  [theme.media.maxSm]: {
    display: "none",
  },
});

type ProfileHeaderT = {
  networkProfile?: InfluencerProfileT;
  isLoading: boolean;
  fixed: boolean;
  setExportType: Dispatch<SetStateAction<"copy" | "save" | null>>;
  exportPrice: string;
  setExportPrice: Dispatch<SetStateAction<string>>;
  exportDescription: string;
  setExportDescription: Dispatch<SetStateAction<string>>;
};

const ProfileHeader: FC<ProfileHeaderT> = ({
  networkProfile,
  isLoading,
  fixed,
  setExportType,
  exportPrice,
  setExportPrice,
  exportDescription,
  setExportDescription,
}) => {
  const MAX_BIO_LENGTH = 150;

  const navigate = useRouterNavigate();

  const { t } = useTranslate("brands.influencer_database.detail");

  if (!networkProfile || isLoading) {
    return (
      <PlaceholderLoader type={PlaceholdersTypeE.camapign_detail_header} />
    );
  }

  const {
    id,
    profile_id,
    avatar_url,
    display_name,
    profile_url,
    bio,
    provider,
  } = networkProfile;

  return (
    <FlexBox
      justifyContent="space-between"
      alignItems="center"
      customWidth={CustomWidthE.full}
      flexDirection="row"
    >
      <FlexBox customWidth={CustomWidthE.full}>
        <FlexBox
          fullHeight
          onClick={() => navigate(NavigationKeyE.Database)}
          cursor="pointer"
        >
          <Spacer />
          <Icon icon={IconTypeE.arrowLeft} size="small" />
        </FlexBox>
        <AvatarWithCircles
          handle={profile_id}
          profileImageUrl={avatar_url}
          provider={provider}
          avatarSize={fixed ? "mini" : "default"}
        />
        <Spacer />
        <FlexBox
          flexDirection="column"
          customWidth={CustomWidthE.full}
          alignItems="flex-start"
        >
          <FlexBox flexDirection="row" gap={rem(4)} alignItems="center">
            <Heading
              spaceBottom={0}
              heading={display_name ? display_name : profile_id}
              headingType={HeadingTypeT.h3}
            />
            <HandleWrapper>
              <BlueLink
                text={provider === "youtube" ? "" : `@${profile_id}`}
                href={profile_url}
                icon={IconTypeE.linkExternal}
                target="_blank"
                iconPosition={"after"}
                fontWeight={400}
                marginTop={"0"}
                event={MixpanelEventT.databaseDetailSocialMediaProfileClick}
              />
            </HandleWrapper>
          </FlexBox>
          <Spacer direction="vertical" size="mini" />

          {!fixed && (
            <>
              <Paragraph truncate={MAX_BIO_LENGTH} paragraph={bio} />
              {bio && bio.length > MAX_BIO_LENGTH && (
                <BlueLink
                  text={t("view_more")}
                  paragraphSize="small"
                  target="_blank"
                  href={profile_url}
                />
              )}
            </>
          )}
        </FlexBox>
      </FlexBox>
      <Spacer />
      <Actions
        networkProfileId={id}
        setExportType={setExportType}
        setExportPrice={setExportPrice}
        setExportDescription={setExportDescription}
        exportPrice={exportPrice}
        exportDescription={exportDescription}
        adminRole={true} // TODO
      />
    </FlexBox>
  );
};

export default ProfileHeader;
