import { DownOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Divider, Dropdown, MenuProps, Space } from "antd";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { numberWithSpaces } from "../../../../../helpers/formaters";
import { MixpanelEventT, trackEvent } from "../../../../../helpers/trackEvent";
import { useResponsive } from "../../../../../hooks/useResponsive";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { breakpoints, rem, theme } from "../../../../../styling/theme";
import ListSelect from "./ListSelect";

const Wrapper = styled(FlexBox)({
  width: "100%",
  justifyContent: "space-between",
  padding: `${rem(20)} ${rem(20)} ${rem(16)} ${rem(20)}`,
  height: rem(56),
  borderBottom: `1px solid ${theme.color.greyLightColor}`,

  [theme.media.maxSm]: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",
    height: "auto",
    flexWrap: "wrap",
    gap: rem(5),
    borderBottom: "none",
  },
});

type TableHeaderT = {
  currentCount: number;
  totalCount: number;
  setSort: (value: string) => void;
  networkProfileIds: string[];
  setNetworkProfileIds: Dispatch<SetStateAction<string[]>>;
  listProfilesCount: number;
  sortingOptions: string[];
  currentSort: string;
};

const TableHeader: FC<TableHeaderT> = ({
  currentCount,
  totalCount,
  setSort,
  networkProfileIds,
  setNetworkProfileIds,
  listProfilesCount,
  sortingOptions,
  currentSort,
}) => {
  const { t } = useTranslate("brands.influencer_database.table");

  const onSortClickHandle = (sortOption: string): void => {
    trackEvent(MixpanelEventT.DatabaseListSort, { sort: sortOption });
    setSort(sortOption);
  };

  const items: MenuProps["items"] = sortingOptions.map((option) => ({
    key: option,
    disabled: currentSort === option,
    label: (
      <a onClick={() => onSortClickHandle(option)}>
        {t(`sorting.${option.replace(":", "_")}`)}
      </a>
    ),
  }));

  const { breakePoint } = useResponsive(breakpoints.sm);

  const totalCountString = (): string => {
    if (listProfilesCount > 0) {
      return `${listProfilesCount} ${t("profiles", {
        count: listProfilesCount,
      })}`;
    }

    const total = numberWithSpaces(totalCount);
    const current = numberWithSpaces(currentCount);

    return `${current} ${t("profiles", { count: currentCount })}`;

    // TODO  - doriesit ci budeme zobrazovat 0 / 203 ...
    if (totalCount === currentCount) {
      return `${total} ${t("profiles")}`;
    }

    return `<b>${current}</b> ${t("from")} ${total} ${t("profiles")}`;
  };

  const showListSelect = (condition: boolean): JSX.Element | undefined => {
    if (condition) {
      return (
        <>
          <Divider type="vertical" />
          <FlexBox gap={rem(10)}>
            <Paragraph
              paragraph={`${t("selected")} ${networkProfileIds.length}`}
              paragraphSize="small"
            />
            <ListSelect
              networkProfileIds={networkProfileIds}
              setNetworkProfileIds={setNetworkProfileIds}
            />
          </FlexBox>
        </>
      );
    } else {
      return undefined;
    }
  };

  return (
    <Wrapper>
      <FlexBox>
        <Paragraph paragraph={totalCountString()} paragraphSize="small" />
        {showListSelect(networkProfileIds.length > 0 && !breakePoint)}
      </FlexBox>
      {listProfilesCount === 0 && (
        <FlexBox gap={rem(8)}>
          <Paragraph
            paragraph={t("sort_by")}
            paragraphSize="small"
            color={theme.color.textGreyColor}
          />
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Paragraph
                  paragraph={t(`sorting.${currentSort.replace(":", "_")}`)}
                  paragraphSize="small"
                />
                <DownOutlined rev="" />
              </Space>
            </a>
          </Dropdown>
        </FlexBox>
      )}
      {showListSelect(networkProfileIds.length > 0 && breakePoint)}
    </Wrapper>
  );
};

export default TableHeader;
