import { DownloadOutlined, FolderAddOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../../components/FlexBox";
import Spacer from "../../../../../components/Spacer";
import Input from "../../../../../features/Input";
import { MixpanelEventT, trackEvent } from "../../../../../helpers/trackEvent";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import Dropdown from "../../../../../ui/Dropdown";
import Icon, { IconTypeE } from "../../../../../ui/icons/Icon";
import ListSelect from "../../influencer-database/table/ListSelect";

const Wrapper = styled(FlexBox)({
  [theme.media.maxSm]: {
    alignSelf: "center",
  },
});

const ExportWrapper = styled(FlexBox)({
  [theme.media.maxSm]: {
    width: 0,
    visibility: "hidden",
  },
});

const ExportContent = styled(FlexBox)({
  padding: rem(4),
});

const StyledInput = styled(Input)({
  width: "100%",
  border: `1px solid ${theme.color.greyColor}`,
  borderRadius: rem(8),
});

type ActionsT = {
  networkProfileId: string;
  setExportType: Dispatch<SetStateAction<"copy" | "save" | null>>;
  exportPrice: string;
  setExportPrice: Dispatch<SetStateAction<string>>;
  exportDescription: string;
  setExportDescription: Dispatch<SetStateAction<string>>;
  adminRole: boolean;
};

const Actions: FC<ActionsT> = ({
  networkProfileId,
  setExportType,
  exportPrice,
  setExportPrice,
  exportDescription,
  setExportDescription,
  adminRole,
}) => {
  const [dropdownHover, setDropdownHover] = useState<
    "lists" | "export" | undefined
  >();
  const { t } = useTranslate("brands.influencer_database");

  const exportHandle = (type: "copy" | "save"): void => {
    setExportType(type);
    setDropdownHover(undefined);

    trackEvent(
      type === "save"
        ? MixpanelEventT.databaseDetailDownloadFile
        : MixpanelEventT.databaseDetailDownloadCopy,
      { networkProfileId: networkProfileId },
    );
  };

  return (
    <Wrapper
      flexDirection="row"
      fullHeight={true}
      justifyContent="flex-end"
      alignItems="flex-start"
    >
      <Spacer size="mini" />
      <FlexBox
        position="relative"
        onMouseEnter={() => setDropdownHover("lists")}
        onMouseLeave={() => setDropdownHover(undefined)}
      >
        <Button
          type={ButtonTypeE.grey}
          size={ButtonSizeE.small}
          onClick={() => {}}
          tooltip={t("table.lists.add_to_list")}
        >
          <FolderAddOutlined style={{ fontSize: rem(20) }} />
        </Button>
        <Dropdown active={dropdownHover === "lists"} width={rem(210)}>
          <ExportContent flexDirection="column" customWidth={CustomWidthE.full}>
            <ListSelect
              networkProfileIds={[networkProfileId]}
              setNetworkProfileIds={() => {}}
            />
            <Spacer direction="vertical" size="micro" />
          </ExportContent>
        </Dropdown>
      </FlexBox>
      <Spacer size="mini" />
      <ExportWrapper>
        <Spacer size="mini" />
        {adminRole ? (
          <FlexBox
            position="relative"
            onMouseEnter={() => setDropdownHover("export")}
            onMouseLeave={() => setDropdownHover(undefined)}
          >
            <Button type={ButtonTypeE.grey} size={ButtonSizeE.small}>
              <DownloadOutlined style={{ fontSize: rem(20) }} />
            </Button>
            <Dropdown active={dropdownHover === "export"} width={rem(210)}>
              <ExportContent
                flexDirection="column"
                customWidth={CustomWidthE.full}
              >
                <StyledInput
                  value={exportPrice}
                  setValue={setExportPrice}
                  placeholder={t("detail.export.price")}
                />
                <Spacer direction="vertical" size="micro" />
                <StyledInput
                  value={exportDescription}
                  setValue={setExportDescription}
                  placeholder={t("detail.export.description")}
                />
                <Spacer direction="vertical" size="micro" />
                <FlexBox customWidth={CustomWidthE.full}>
                  <Button
                    customWidth={CustomWidthE.half}
                    size={ButtonSizeE.small}
                    onClick={() => exportHandle("save")}
                  >
                    {t("detail.export.save")}
                  </Button>
                  <Spacer size="micro" />
                  <Button
                    customWidth={CustomWidthE.half}
                    size={ButtonSizeE.small}
                    onClick={() => exportHandle("copy")}
                  >
                    {t("detail.export.copy")}
                  </Button>
                </FlexBox>
              </ExportContent>
            </Dropdown>
          </FlexBox>
        ) : (
          <Button
            type={ButtonTypeE.grey}
            size={ButtonSizeE.small}
            onClick={() => exportHandle("save")}
            tooltip={t("detail.export.save")}
          >
            <Icon icon={IconTypeE.download} />
          </Button>
        )}
        <Spacer />
      </ExportWrapper>
    </Wrapper>
  );
};

export default Actions;
