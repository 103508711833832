import { Select } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import FlexBox, { CustomWidthE } from "../../../../../../components/FlexBox";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import FilterContainer from "../../components/FilterContainer";

type OptionT = {
  value: number;
  growValue: string;
  label: string;
  operator: "gte" | "lte";
};

const TotalLikesGrowth: FC = () => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );

  const total_likes_growth = searchGlobalQuery.total_likes_growth;

  const { t } = useTranslate(
    "brands.influencer_database.filter.total_likes_growth",
  );

  const intervalOptions = [
    { value: "", label: t("placeholder.interval") },
    { value: "i1month", label: `1 ${t("month", { count: 1 })}` },
    { value: "i2months", label: `2 ${t("month", { count: 2 })}` },
    { value: "i3months", label: `3 ${t("month", { count: 3 })}` },
    { value: "i4months", label: `4 ${t("month", { count: 4 })}` },
    { value: "i5months", label: `5 ${t("month", { count: 5 })}` },
    { value: "i6months", label: `6 ${t("month", { count: 6 })}` },
  ];

  const defaultOption: OptionT = {
    value: 1,
    growValue: (0.1).toString(),
    label: ">10%",
    operator: "gte",
  };
  const valueOptions: OptionT[] = [defaultOption];
  for (let i = 2; i <= 40; i++) {
    valueOptions.push({
      value: i,
      growValue: (0.1 * i).toString(),
      label: `>${10 * i}%`,
      operator: "gte",
    });
  }
  for (let i = 0; i <= 39; i++) {
    valueOptions.push({
      value: i + 41,
      growValue: (1.1 + 0.1 * i).toString(),
      label: `<${110 + 10 * i}%`,
      operator: "lte",
    });
  }

  const getGrowValueForId = (id: number): OptionT => {
    return valueOptions.find((option) => option.value === id) || defaultOption;
  };

  const setInterval = (value: string): void => {
    let growth = undefined;

    if (value !== "") {
      const defaultOption = getGrowValueForId(1);

      growth = {
        interval: value,
        value:
          searchGlobalQuery.total_likes_growth?.value ||
          defaultOption.growValue,
        operator:
          searchGlobalQuery.total_likes_growth?.operator ||
          defaultOption.operator,
      };
    }

    setPartialSearchGlobalQuery({ total_likes_growth: growth });
  };

  const setValue = (value: number): void => {
    const option = getGrowValueForId(value);

    setPartialSearchGlobalQuery({
      total_likes_growth: {
        interval: searchGlobalQuery.total_likes_growth?.interval || "i1month",
        value: option.growValue,
        operator: option.operator,
      },
    });
  };

  const getvalue = (value: string | undefined): number => {
    return (
      valueOptions.find((option) => option.growValue === value)?.value || 1
    );
  };

  return (
    <FilterContainer tooltip={false} closable={["total_likes_growth"]} t={t}>
      <FlexBox
        customWidth={CustomWidthE.full}
        flexDirection="column"
        gap={rem(8)}
      >
        <Select
          style={{ width: "100%" }}
          placeholder={t("placeholder.interval")}
          options={intervalOptions}
          value={total_likes_growth ? total_likes_growth.interval : ""}
          onChange={(value) => setInterval(value)}
        />
        <Select
          style={{ width: "100%" }}
          options={valueOptions}
          disabled={!total_likes_growth || !total_likes_growth.interval}
          defaultValue={defaultOption.value}
          value={getvalue(total_likes_growth?.value)}
          onChange={(value) => setValue(value)}
        />
      </FlexBox>
    </FilterContainer>
  );
};

export default TotalLikesGrowth;
