import * as React from "react";
import { FC, useContext } from "react";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import DoubleFilter from "../../components/DoubleFilter";
import { GenderTypeT, GlobalGenderTypeT } from "../../types";
import FollowersGender from "../FollowersGender";
import InfluencerGender from "../global/InfluencerGender";

const Gender: FC = () => {
  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );
  const { t } = useTranslate("brands.influencer_database.filter.gender");

  type audienceGenderReturnT = {
    type: GenderTypeT | undefined;
    weight: number;
  };
  const audienceGender = (): audienceGenderReturnT => {
    const {
      audience_gender: { code },
    } = searchGlobalQuery;

    const type =
      code.length > 0 ? (code.toLowerCase() as GenderTypeT) : undefined;
    return {
      type,
      weight: parseFloat(searchGlobalQuery.audience_gender.weight) * 100,
    };
  };

  const setFollowersGender = (
    type: GenderTypeT | undefined,
    from: number,
  ): void => {
    const code = type ? (type.toUpperCase() as GlobalGenderTypeT) : "";
    setPartialSearchGlobalQuery({
      audience_gender: { code: code, weight: type ? `${from / 100}` : "" },
    });
  };

  const gender = (): GenderTypeT => {
    const options = {
      MALE: "male",
      FEMALE: "female",
      KNOWN: "known",
      UNKNOWN: "unknown",
      "": undefined,
    };

    return options[searchGlobalQuery.gender.code] as GenderTypeT;
  };

  const setGender = (val: string): void => {
    const gender = {
      male: "MALE",
      female: "FEMALE",
      known: "KNOWN",
      unknown: "UNKNOWN",
      undefined: "",
    }[val] as GlobalGenderTypeT;
    setPartialSearchGlobalQuery({ gender: { code: gender } });
  };

  return (
    <DoubleFilter
      influencerFilter={
        <InfluencerGender value={gender()} setValue={setGender} />
      }
      audienceFilter={
        <FollowersGender
          type={audienceGender().type}
          from={audienceGender().weight}
          setSearchValue={setFollowersGender}
        />
      }
      t={t}
    />
  );
};

export default Gender;
