import { CheckCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Tag } from "antd";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../../../components/FlexBox";
import Paragraph from "../../../../../components/Paragraph";
import { designToken } from "../../../../../helpers/antDesign";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import { ModalElipsesBg } from "../../../../../ui/icons/CustomIcons";
import { NavigationKeyE, useRouterNavigate } from "../../../router";

const imgUrl = require("../../../../../assets/global_doggo.jpg");

const Wrapper = styled(FlexBox)({
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: rem(12),
});

const LeftColumn = styled(FlexBox)({
  flexDirection: "column",
  alignItems: "flex-start",
  gap: rem(24),
  width: rem(582),
});

const Img = styled.img({
  display: "block",

  [theme.media.maxSm]: {
    display: "none",
  },
});

const ImgBg = styled(ModalElipsesBg)({
  overflow: "hidden",
  position: "absolute",
  top: 0,
  right: 0,
  height: "100%",

  [theme.media.maxSm]: {
    display: "none",
  },
});

const Check = styled(CheckCircleOutlined)({
  padding: `${rem(4)} ${rem(12)} 0 0`,
  color: designToken["cyan-6"],
});

type GlobalModalT = {
  onCloseGlobalModal: () => void;
};

const GlobalModal: FC<GlobalModalT> = ({ onCloseGlobalModal }) => {
  const navigate = useRouterNavigate();
  const { t } = useTranslate("brands.influencer_database");

  const row = (title: string, description: string): JSX.Element => (
    <FlexBox alignItems="flex-start">
      <Check />
      <FlexBox flexDirection="column" alignItems="flex-start">
        <Paragraph paragraph={title} paragraphSize="small" fontWeight={600} />
        <Paragraph paragraph={description} paragraphSize="small" />
      </FlexBox>
    </FlexBox>
  );

  return (
    <Wrapper>
      <LeftColumn>
        <FlexBox flexDirection="column" alignItems="flex-start" gap={rem(8)}>
          <Tag color={designToken.colorPrimaryBg}>
            <Paragraph
              paragraph={t("global_db.new")}
              color={designToken.colorPrimary}
              paragraphSize="micro"
            />
          </Tag>
          <Paragraph
            paragraph={t("global_db.title")}
            paragraphSize="large"
            fontWeight={600}
          />
        </FlexBox>
        <FlexBox flexDirection="column" alignItems="flex-start" gap={rem(12)}>
          {row(
            t("global_db.free_czsk.title"),
            t("global_db.free_czsk.description"),
          )}
          {row(t("global_db.credit.title"), t("global_db.credit.description"))}
          {row(t("global_db.gift.title"), t("global_db.gift.description"))}
        </FlexBox>
        <FlexBox gap={rem(12)}>
          <Button
            type="primary"
            onClick={() => navigate(NavigationKeyE.Subscription)}
          >
            {t("global_db.subscription")}
          </Button>
          <Button onClick={onCloseGlobalModal}>{t("global_db.close")}</Button>
        </FlexBox>
      </LeftColumn>
      <FlexBox>
        <Img src={imgUrl} alt="Global DB" />
      </FlexBox>
      <ImgBg />
    </Wrapper>
  );
};

export default GlobalModal;
