import { ExportOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../styling/theme";
import Avatar from "./Avatar";
import FlexBox, { AlignT, CursorT } from "./FlexBox";
import Paragraph, { ParagraphSizeT } from "./Paragraph";

const AvatarWrapper = styled.div({
  position: "relative",
});

const ExternalLink = styled(FlexBox)({
  gap: rem(4),
  borderBottom: `1px solid transparent`,
  cursor: "pointer",

  "&:hover": {
    borderBottom: `1px solid ${theme.color.primaryColor}`,

    svg: {
      color: theme.color.primaryColor,
    },
  },
});

export enum AvatarSizeE {
  mini = 24,
  small = 32,
  default = 40,
  big = 64,
}

type AvatarTileT = {
  handle?: string;
  name?: string;
  imageUrl: string | null;
  network?: string;
  avatarSize?: number;
  maxSize?: number | "auto";
  cursor?: CursorT;
  justifyContent?: AlignT;
  externalLink?: string;
  paragraphSize?: ParagraphSizeT;
  handleNameGap?: number;
};

type HandlesWrapperStyleT = {
  maxSize: number | "auto";
};

export const HandlesWrapper = styled(FlexBox)<HandlesWrapperStyleT>(
  ({ maxSize }) => ({
    maxWidth: typeof maxSize === "string" ? "none" : rem(maxSize),
  }),
);

const AvatarTile: FC<AvatarTileT> = ({
  imageUrl,
  handle,
  network,
  avatarSize = AvatarSizeE.default,
  maxSize = "auto",
  name,
  cursor,
  justifyContent = "center",
  externalLink,
  paragraphSize = "default",
  handleNameGap = 0,
}) => {
  const handleOnClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void => {
    e.stopPropagation();

    if (externalLink) {
      window.open(externalLink, "_blank");
    }
  };

  return (
    <FlexBox justifyContent={justifyContent} gap={rem(8)}>
      <AvatarWrapper>
        <Avatar
          src={imageUrl}
          name={handle ? handle : ""}
          size={avatarSize}
          network={network}
        />
      </AvatarWrapper>
      <HandlesWrapper
        maxSize={maxSize}
        flexDirection="column"
        alignItems="flex-start"
        cursor={cursor}
        gap={rem(handleNameGap)}
      >
        {handle && (
          <FlexBox gap={rem(4)}>
            <Paragraph
              paragraph={handle}
              truncate="css"
              color={theme.color.blackColor}
              cursor="inherit"
              paragraphSize={paragraphSize}
            />
            {externalLink && (
              <ExternalLink onClick={(e) => handleOnClick(e)}>
                <ExportOutlined
                  style={{
                    color: theme.color.textGreyColor,
                    fontSize: rem(12),
                  }}
                />
              </ExternalLink>
            )}
          </FlexBox>
        )}
        {name && (
          <FlexBox gap={rem(4)}>
            <Paragraph
              paragraph={name}
              truncate="css"
              color={theme.color.textGreyColor}
              cursor="inherit"
              paragraphSize={paragraphSize}
            />
            {!handle && externalLink && (
              <ExternalLink onClick={(e) => handleOnClick(e)}>
                <ExportOutlined
                  style={{
                    color: theme.color.textGreyColor,
                    fontSize: rem(12),
                  }}
                />
              </ExternalLink>
            )}
          </FlexBox>
        )}
      </HandlesWrapper>
    </FlexBox>
  );
};

export default AvatarTile;
