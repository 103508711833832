import * as React from "react";
import { createContext, FC, useContext, useState } from "react";
import {
  defaultGeos,
  defaultWeight,
} from "../features/influencer-database/filters/global/Countries";
import { SearchQueryGlobalT } from "../features/influencer-database/types";
import { SubscriptionContext } from "./SubscriptionsContextProvider";

export const defaultGlobalSearchQuery: SearchQueryGlobalT = {
  platform: "instagram",
  account_type: [],
  age: { left_number: "", right_number: "" },
  audience_lang: { code: "", weight: "" },
  audience_age_range: undefined,
  audience_brand_category: [],
  audience_credibility_class: [],
  audience_gender: { code: "", weight: "" },
  audience_geo: [],
  audience_race: { code: "", weight: "" },
  brand_category: [],
  engagement_rate: { value: undefined, operator: "gte" },
  engagements: { left_number: "", right_number: "" },
  followers_growth: undefined,
  followers: { left_number: "", right_number: "" },
  gender: { code: "" },
  geo: [],
  keywords: "",
  lang: { code: "" },
  last_posted: "",
  reels_plays: { left_number: "", right_number: "" },
  saves: { left_number: "", right_number: "" },
  shares: { left_number: "", right_number: "" },
  text_tags: [],
  text: "",
  total_likes_growth: undefined,
  views: { left_number: "", right_number: "" },
  with_contact: [],
  tags: [],
  recently_analyzed_profiles: false,
  sort: { field: "followers", direction: "desc" },
};

const czskGlobalSearchQuery: SearchQueryGlobalT = {
  ...defaultGlobalSearchQuery,
  audience_geo: defaultGeos.map((geo) => ({
    id: geo.id.toString(),
    weight: defaultWeight,
  })),
  followers: { left_number: "5000", right_number: "" },
  gender: { code: "KNOWN" },
};

type SearchGlobalQueryContextT = {
  searchGlobalQuery: SearchQueryGlobalT;
  setPartialSearchGlobalQuery: (query: Partial<SearchQueryGlobalT>) => void;
  defaultQuery: SearchQueryGlobalT;
};

export const SearchGlobalQueryContext =
  createContext<SearchGlobalQueryContextT>({
    searchGlobalQuery: defaultGlobalSearchQuery,
    setPartialSearchGlobalQuery: () => {},
    defaultQuery: defaultGlobalSearchQuery,
  });

export const SearchGlobalQueryContextProvider: FC = ({ children }) => {
  const { hasMarketAccess } = useContext(SubscriptionContext);

  const defaultQuery = hasMarketAccess()
    ? czskGlobalSearchQuery
    : defaultGlobalSearchQuery;

  const [searchGlobalQuery, setSearchGlobalQuery] =
    useState<SearchQueryGlobalT>(defaultQuery);

  const setPartialSearchGlobalQuery = (
    query: Partial<SearchQueryGlobalT>,
  ): void => {
    setSearchGlobalQuery((prev) => ({ ...prev, ...query }));
  };

  return (
    <SearchGlobalQueryContext.Provider
      value={{ searchGlobalQuery, setPartialSearchGlobalQuery, defaultQuery }}
    >
      {children}
    </SearchGlobalQueryContext.Provider>
  );
};
