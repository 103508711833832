import axios from "axios";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { DictionaryGeoT } from "../types";

export const getDictionaryGeos = async (
  value: string,
): Promise<DictionaryGeoT[]> => {
  return axios
    .get(
      getUrlWithQuery(getApiUrl(ApiRoutesE.DICTIONARY_GEOS), {
        q: value,
        count_type: "search",
      }),
    )
    .then((res) => res.data.geos);
};
