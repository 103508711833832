import { Radio, Select, Space } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import FlexBox from "../../../../../../components/FlexBox";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import FilterContainer from "../../components/FilterContainer";

const defaultWeight = "0.3";

const Ethnicity: FC = () => {
  const percentageArr = [
    1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90,
    95,
  ];
  const percentageOptions = percentageArr.map((percentage) => ({
    label: `>${percentage}%`,
    value: percentage,
  }));

  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );
  const { t } = useTranslate("brands.influencer_database.filter.ethnicity");

  const onPercentageChange = (value: number): void => {
    const weight = (value / 100).toString();

    setPartialSearchGlobalQuery({
      audience_race: { ...searchGlobalQuery.audience_race, weight: weight },
    });
  };

  return (
    <FilterContainer closable={["audience_race"]} t={t}>
      <FlexBox
        gap={rem(16)}
        alignItems="flex-start"
        flexDirection="column"
        customWidth="100%"
      >
        <Radio.Group
          name="ethnicity"
          value={searchGlobalQuery.audience_race.code}
          defaultValue={""}
          onChange={(e) =>
            setPartialSearchGlobalQuery({
              audience_race: { code: e.target.value, weight: defaultWeight },
            })
          }
        >
          <Space direction="vertical">
            <Radio value="">{t("any")}</Radio>
            <Radio value="Black">{t("Black")}</Radio>
            <Radio value="Asian">{t("Asian")}</Radio>
            <Radio value="White">{t("White")}</Radio>
            <Radio value="Hispanic">{t("Hispanic")}</Radio>
          </Space>
        </Radio.Group>
        {searchGlobalQuery.audience_race.code.length > 0 && (
          <Select
            value={parseFloat(searchGlobalQuery.audience_race.weight) * 100}
            options={percentageOptions}
            onChange={(value) => onPercentageChange(value)}
            style={{ width: "100%" }}
          />
        )}
      </FlexBox>
    </FilterContainer>
  );
};

export default Ethnicity;
