import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { DictionaryInterestT } from "../types";

export const getDictionaryInterests = async (): Promise<
  DictionaryInterestT[]
> => {
  return axios
    .get(getApiUrl(ApiRoutesE.DICTIONARY_INTERESTS))
    .then((res) => res.data.interests);
};
