import { debounce, uniqBy } from "lodash";
import * as React from "react";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { QueryKeys } from "../../../../../../helpers/queryKeys";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import { getDictionaryGeos } from "../../../../data-access/getDictionaryGeos";
import { DictionaryGeoT } from "../../../../types";
import DoubleFilter from "../../components/DoubleFilter";
import SelectWithAdvancedOptions from "../../components/SelectWithAdvancedOptions";
import LocationSelect from "./LocationSelect";

export const defaultWeight = "0.15";
export const defaultGeos: DictionaryGeoT[] = [
  {
    id: 51684,
    name: "Czech Republic",
    title: "Czech Republic",
    type: ["country"],
    country: { id: 51684, code: "CZ" },
  },
  {
    id: 14296,
    name: "Slovakia",
    title: "Slovakia",
    type: ["country"],
    country: { id: 14296, code: "SK" },
  },
];

const Countries: FC = () => {
  const [geos, setGeos] = useState<DictionaryGeoT[]>(defaultGeos);
  const [searchValue, setSearchValue] = useState<string>("");

  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );
  const { t } = useTranslate("brands.influencer_database.filter.location");

  const { isLoading, refetch } = useQuery(
    QueryKeys.DICTIONARY_GEOS,
    () => getDictionaryGeos(searchValue),
    {
      enabled: false,
      onSuccess: (data) => {
        setGeos((prev) => uniqBy([...prev, ...data], "id"));
      },
    },
  );

  const debounceFetcher = useMemo(() => {
    return debounce(refetch, 500);
  }, [searchValue]);

  const onSelectHandle = (id: number | string | null): void => {
    if (
      id === null ||
      searchGlobalQuery.audience_geo.map((g) => g.id).includes(id.toString())
    )
      return;

    const added = geos.find((g) => g.id === id);
    added &&
      setPartialSearchGlobalQuery({
        audience_geo: [
          ...searchGlobalQuery.audience_geo,
          { id: added.id.toString(), weight: defaultWeight },
        ],
      });
  };

  const onDeselectHandle = (id: number | string | null): void => {
    if (id === null) return;

    setPartialSearchGlobalQuery({
      audience_geo: searchGlobalQuery.audience_geo.filter(
        (geo) => geo.id !== id.toString(),
      ),
    });
  };

  useEffect(() => {
    searchValue.length > 0 && debounceFetcher();
  }, [searchValue]);

  return (
    <DoubleFilter
      audienceFilter={
        <SelectWithAdvancedOptions
          searchQuery={searchGlobalQuery}
          setFilterSearchQuery={setPartialSearchGlobalQuery}
          attribute="audience_geo"
          onSelectHandle={onSelectHandle}
          onDeselectHandle={onDeselectHandle}
          onSearchHandle={setSearchValue}
          options={geos.map((geo) => ({
            id: geo.id,
            name: geo.name,
          }))}
          t={t}
          isLoading={isLoading}
        />
      }
      influencerFilter={
        <LocationSelect
          searchQuery={searchGlobalQuery}
          setFilterSearchQuery={setPartialSearchGlobalQuery}
        />
      }
      t={t}
    />
  );
};

export default Countries;
