import { Select } from "antd";
import * as React from "react";
import { FC, useContext } from "react";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { SearchGlobalQueryContext } from "../../../../context-providers/SearchGlobalQueryContextProvider";
import FilterContainer from "../../components/FilterContainer";

type TagsT = {
  tags: string[];
};

const Tags: FC<TagsT> = ({ tags }) => {
  const options = tags.map((tag) => ({ label: tag, value: tag }));

  const { searchGlobalQuery, setPartialSearchGlobalQuery } = useContext(
    SearchGlobalQueryContext,
  );

  const { t } = useTranslate("brands.influencer_database.filter.tags");

  const onChangeHandle = (values: string[]): void => {
    setPartialSearchGlobalQuery({ tags: values });
  };

  return (
    <FilterContainer t={t} tooltip={false}>
      <Select
        mode="multiple"
        value={searchGlobalQuery.tags}
        options={options}
        maxTagCount="responsive"
        style={{ width: "100%" }}
        onChange={onChangeHandle}
        placeholder={t("placeholder")}
      />
    </FilterContainer>
  );
};

export default Tags;
