import axios from "axios";
import { getUrlWithQuery } from "../../../helpers/getUrlWithQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { SearchQueryGlobalT } from "../features/influencer-database/types";
import { SearchResultResponseT } from "../types";

const LIMIT = 10;

export const getSearchResult = async (
  query: SearchQueryGlobalT,
  pageParam: number,
): Promise<SearchResultResponseT> => {
  const platform = query.platform;

  return axios
    .post(
      getUrlWithQuery(getApiUrl(ApiRoutesE.DATABASE_SEARCH_RESULTS), {
        platform,
      }),
      {
        search: query,
        paging: { skip: (pageParam - 1) * LIMIT, limit: LIMIT },
        sort: query.sort,
      },
    )
    .then((res) => res.data);
};
