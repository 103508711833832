import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { rem, theme } from "../../../../../styling/theme";
import AccordionIframe from "../../../ui/AccordionIframe";
import ColourWrapper from "../../../ui/ColourWrapper";
import Faq from "../../../ui/Faq";
import Footer from "../../../ui/Footer";
import { Heading } from "../../../ui/Header";
import InfoHeader from "../../../ui/InfoHeader";
import TextWrapper from "../../../ui/TextWrapper";
import { ModalAttributesT } from "../ApprovalRequestContainer";

const ImgChecklist = styled.img`
  position: absolute;
  right: ${rem(33)};
  top: ${rem(27)};
  height: ${rem(56)};
  width: auto;
  ${theme.media.lg} {
    position: inherit;
    right: ${rem(84)};
    top: auto;
    height: auto;
    padding-right: ${rem(54)};
  }
`;

const Checklist = require("../../../../../assets/fb-approval/icons/checklist.png");

type TutorialsT = {
  setModalAttributes: Dispatch<SetStateAction<ModalAttributesT | undefined>>;
};

const Tutorials: FC<TutorialsT> = ({ setModalAttributes }) => {
  const { t } = useTranslate("influencer_root.approval_request.tutorials");

  const accordionItems = [
    {
      header: <InfoHeader stepIndex={1}>{t("faq.item1.title")}</InfoHeader>,
      content: (
        <AccordionIframe
          title={t("faq.item1.title")}
          youtubeIds={{
            desktop: t("faq.item1.youtube"),
            portrait: t("faq.item1.youtube"),
          }}
          text={t("faq.item1.text")}
          setModalAttributes={setModalAttributes}
        />
      ),
    },
    {
      header: <InfoHeader stepIndex={2}>{t("faq.item2.title")}</InfoHeader>,
      content: (
        <AccordionIframe
          title={t("faq.item2.title")}
          youtubeIds={{
            desktop: t("faq.item2.youtube"),
            portrait: t("faq.item2.youtube"),
          }}
          text={t("faq.item2.text")}
          setModalAttributes={setModalAttributes}
        />
      ),
    },
    {
      header: <InfoHeader stepIndex={3}>{t("faq.item3.title")}</InfoHeader>,
      content: (
        <AccordionIframe
          title={t("faq.item3.title")}
          youtubeIds={{
            desktop: t("faq.item3.youtube"),
            portrait: t("faq.item3.youtube"),
          }}
          text={t("faq.item3.text")}
          setModalAttributes={setModalAttributes}
        />
      ),
    },
    {
      header: <InfoHeader stepIndex={4}>{t("faq.item4.title")}</InfoHeader>,
      content: (
        <AccordionIframe
          title={t("faq.item4.title")}
          youtubeIds={{}}
          text={t("faq.item4.text")}
          setModalAttributes={setModalAttributes}
        />
      ),
    },
  ];

  return (
    <>
      <Heading>{t("title")}</Heading>
      <ColourWrapper
        title={t("box.title")}
        text={t("box.text")}
        img={<ImgChecklist src={Checklist} alt="shield" />}
      />
      <TextWrapper title={t("description.title")} text={""} />
      <Faq data={accordionItems} />
      <Footer checkbox={true} />
    </>
  );
};

export default Tutorials;
